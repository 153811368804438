import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");

  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const useProfile = () => {
  const location = useLocation();

  const userProfileSession = getLoggedinUser();
  var token = userProfileSession && userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = userProfileSession && userProfileSession["token"];
    setLoading(token ? false : true);
    setUserProfile(userProfileSession ? userProfileSession : null);
  }, [location]);

  return { userProfile, loading, token };
};

export { useProfile };
