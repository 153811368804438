import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPackages } from "../../slices/apps/packageSlice";

// sayfalar, modallar vb.
import PackageTable from "./PackageTable";

const PackageAdmin = () => {
  const dispatch = useDispatch();

  const { packagesRefresh } = useSelector((state) => {
    return {
      packagesRefresh: state.package.packagesRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, packagesRefresh]);

  document.title = "Paket Listesi | Global Assistance";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <PackageTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PackageAdmin;
