import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} © Global Assistance. <br />
              <a
                href="https://globalassistance.com.tr/kvkk"
                target="_blank"
                style={{ textDecoration: "none", color: "#007bff" }}
              >
                Kişisel Verilerin Korunması Kanunu (KVKK)
              </a>{" "}
              |{" "}
              <a
                href="https://globalassistance.com.tr/mesafeli-satis-sozlesmesi"
                target="_blank"
                style={{ textDecoration: "none", color: "#007bff" }}
              >
                Mesafeli Satış Sözleşmesi
              </a>
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Made with <i className="mdi mdi-heart text-danger"></i> by
                Çemrek Bilgi Teknolojileri
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
