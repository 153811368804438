import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCustomer, updateCustomer } from "../../slices/apps/customerSlice";

// router
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp = /^[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}$/;
const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;

const CustomerUpdateModal = ({ id }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false); // Acente ekleme modalı

  const { customer, roles, agencies, updateCustomerLoading } = useSelector(
    (state) => {
      return {
        customer: state.customer.customer,
        updateCustomerLoading: state.customer.updateCustomerLoading,
        roles: state.user.roles,
        agencies: state.agency.agencies,
      };
    },
    shallowEqual
  );

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchCustomer(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);

        customerValidation.resetForm();
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  const customerValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: id,
      customerName: customer?.CustomerName,
      phoneNumber: customer?.PhoneNumber,
      email: customer?.Email,
      isActive: true,
      taxNumber: customer?.TaxNumber,
      taxOffice: customer?.TaxOffice,
      address: customer?.Address,
      agencyId: customer?.AgencyId,
    },

    validationSchema: Yup.object({
      customerName: Yup.string().required("Lütfen ad soyad girin"),
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      taxNumber: Yup.string()
        .matches(/^[0-9]+$/, "Vergi numarası sadece sayılardan oluşmalıdır")
        .min(10, "Vergi numarası en az 10 karakter olmalıdır")
        .max(11, "Vergi numarası en fazla 11 karakter olmalıdır")
        .required("Lütfen vergi numarası girin"),
      taxOffice: Yup.string().required("Lütfen vergi dairesi girin"),
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      address: Yup.string()
        .required("Lütfen adres bilgisi girin")
        .min(2, "Lütfen adres bilgisi girin"),
      agencyId: Yup.string().required("Lütfen acente seçin"),
    }),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updateCustomer(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(false);
      }
    },
  });

  return (
    <Fragment>
      <i
        id={`customer-update-tooltipTop-${id}`}
        className="bx bxs-edit text-dark"
        style={{
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={() => tog_large(true)}
      />

      <UncontrolledTooltip
        placement="top"
        target={`customer-update-tooltipTop-${id}`}
      >
        Müşteri Bilgilerini Düzenle
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            setModal(false);
          }}
        >
          Müşteri Bilgilerini Düzenle
        </ModalHeader>

        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              customerValidation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Row className="align-items-start">
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="customerName" className="form-label">
                    Ad Soyad
                  </Label>
                  <Input
                    name="customerName"
                    className="form-control"
                    placeholder="Ad Soyad"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.customerName || ""}
                    invalid={
                      customerValidation.touched.customerName &&
                      customerValidation.errors.customerName
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.customerName &&
                    customerValidation.errors.customerName && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.customerName}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="agencyIdSelect" className="form-label">
                    Acente
                  </Label>
                  <Select
                    placeholder="Acente seçiniz"
                    id="agencyIdSelect"
                    name="agencyId"
                    options={agencies.map((item) => ({
                      label: item.companyName,
                      value: item.id,
                    }))}
                    value={agencies
                      .map((item) => ({
                        label: item.companyName,
                        value: item.id,
                      }))
                      .find(
                        (item) =>
                          item.value === customerValidation.values.agencyId
                      )}
                    onChange={(value) => {
                      customerValidation.setFieldValue("agencyId", value.value);
                    }}
                    onBlur={customerValidation.handleBlur}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          customerValidation.touched.agencyId &&
                          customerValidation.errors.agencyId &&
                          !state.isFocused
                            ? "#e30a17"
                            : "#e9ebec",
                      }),
                    }}
                  />
                  {customerValidation.touched.agencyId &&
                    customerValidation.errors.agencyId && (
                      <div className="d-block invalid-feedback">
                        {customerValidation.errors.agencyId}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    E-posta Adresi
                  </Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="E-posta Adresi"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.email || ""}
                    invalid={
                      customerValidation.touched.email &&
                      customerValidation.errors.email
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.email &&
                    customerValidation.errors.email && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.email}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="phoneNumber">
                    Telefon Numarası
                  </Label>
                  <InputGroup>
                    <span
                      className="input-group-text "
                      style={{
                        width: "44px",
                        justifyContent: "center",
                      }}
                      id="inputGroupPrepend2"
                    >
                      0
                    </span>
                    <ReactInputMask
                      mask="999 999 99 99"
                      maskChar=" "
                      name="phoneNumber"
                      alwaysShowMask
                      className={
                        customerValidation.touched.phoneNumber &&
                        customerValidation.errors.phoneNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={customerValidation.handleChange}
                      value={customerValidation.values.phoneNumber || ""}
                    />
                  </InputGroup>
                  {customerValidation.touched.phoneNumber &&
                    customerValidation.errors.phoneNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {customerValidation.errors.phoneNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="taxNumber">
                    Vergi Numarası
                  </Label>
                  <InputGroup>
                    <ReactInputMask
                      mask="99999999999"
                      maskChar=" "
                      name="taxNumber"
                      alwaysShowMask
                      inputMode="numeric"
                      type="tel"
                      className={
                        customerValidation.touched.taxNumber &&
                        customerValidation.errors.taxNumber
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={customerValidation.handleChange}
                      value={customerValidation.values.taxNumber || ""}
                    />
                  </InputGroup>
                  {customerValidation.touched.taxNumber &&
                    customerValidation.errors.taxNumber && (
                      <div type="invalid" className="d-block invalid-feedback">
                        {customerValidation.errors.taxNumber}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="taxOffice" className="form-label">
                    Vergi Dairesi
                  </Label>
                  <Input
                    name="taxOffice"
                    className="form-control"
                    placeholder="Vergi Dairesi"
                    type="text"
                    autoComplete="off"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.taxOffice || ""}
                    invalid={
                      customerValidation.touched.taxOffice &&
                      customerValidation.errors.taxOffice
                        ? true
                        : false
                    }
                  />
                  {customerValidation.touched.taxOffice &&
                    customerValidation.errors.taxOffice && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.taxOffice}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="address" className="form-label">
                    Adres
                  </Label>
                  <Input
                    name="address"
                    className="form-control"
                    placeholder="Mahalle, cadde, sokak..."
                    type="textarea"
                    autoComplete="off"
                    rows="2"
                    onChange={customerValidation.handleChange}
                    onBlur={customerValidation.handleBlur}
                    value={customerValidation.values.address || ""}
                    invalid={
                      customerValidation.touched.address &&
                      customerValidation.errors.address
                        ? true
                        : false
                    }
                    style={{ resize: "none" }}
                  />
                  {customerValidation.touched.address &&
                    customerValidation.errors.address && (
                      <FormFeedback type="invalid">
                        {customerValidation.errors.address}
                      </FormFeedback>
                    )}
                </div>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 mt-4">
              <Button
                className="btn btn-sm ms-auto nexttab nexttab"
                color="primary"
                type="submit"
                disabled={updateCustomerLoading}
              >
                Kaydet
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CustomerUpdateModal;
