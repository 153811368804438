import axios from "axios";
import { logoutUser } from "../apps/authSlice";

const authCheckMiddleware = (store) => (next) => async (action) => {
  if (
    action.error &&
    (action.error.message.includes("400") ||
      action.error.message.includes("401") ||
      action.error.message.includes("Network Error"))
  ) {
    window.location.reload();
    store.dispatch(logoutUser());
    return;
  }

  // auth ve layout ile ilgili action'lar için authCheck yapmadan devam et
  if (
    action.type.startsWith("auth/") ||
    action.type.startsWith("LayoutSlice/")
  ) {
    return next(action);
  }

  if (action.type.endsWith("/fulfilled")) {
    try {
      const user = localStorage.getItem("authUser");

      if (user) {
        const data = JSON.parse(user);

        const authResult = await axios.get(
          `https://api.globalassistance.com.tr/Account/AuthCheck`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );

        // const authResult = await axios.get(
        //   `http://192.168.50.12:68/Account/AuthCheck`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${data.token}`,
        //     },
        //   }
        // );

        const hasChangesRole = data.data.Role !== authResult.data.result.Role;
        const hasChangesUser =
          data.data.NameSurname !== authResult.data.result.NameSurname;

        if (hasChangesRole) {
          store.dispatch(logoutUser());
          window.location.reload();
          return;
        } else if (hasChangesUser) {
          data.data.NameSurname = authResult.data.result.NameSurname;

          localStorage.setItem("authUser", JSON.stringify(data));
          return next(action);
        }
      } else {
        throw new Error("Kullanıcı bulunamadı!");
      }
    } catch (error) {
      if (error.message.includes("401")) {
        window.location.reload();
        store.dispatch(logoutUser());
        return;
      }
    }
  }

  return next(action);
};

export default authCheckMiddleware;
