import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Form,
//   FormFeedback,
//   Input,
//   InputGroup,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   Spinner,
//   TabContent,
//   TabPane,
// } from "reactstrap";

// // redux toolkit
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import {
//   changePassword,
//   fetchUser,
//   updateUser,
// } from "../../slices/apps/userSlice";
// import { logoutUser } from "../../slices/apps/authSlice";

// // router
// import { useNavigate, useSearchParams } from "react-router-dom";

// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// // diğer importlar
// import classnames from "classnames";
// import ReactInputMask from "react-input-mask";

// // hooks
// import { useProfile } from "../../Components/Hooks/UserHooks";

// // görsel importlar
// import logo from "../../assets/images/avatar.png";

const Profile = () => {
  // const dispatch = useDispatch();
  // const { userProfile } = useProfile();

  // const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const tab = searchParams.get("tab");

  // const [tabs] = useState(["0", "1"]);
  // const [activeTab, setActiveTab] = useState("0");
  // const [isProfileEdit, setIsProfileEdit] = useState(false);

  // const { user, userLoading, userRefresh } = useSelector((state) => {
  //   return {
  //     user: state.user.user,
  //     userLoading: state.user.userLoading,
  //     userRefresh: state.user.userRefresh,
  //   };
  // }, shallowEqual);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await dispatch(fetchUser(userProfile.data.id));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   if (userProfile) {
  //     fetchData();
  //   }
  // }, [userRefresh]);

  // useEffect(() => {
  //   if (tab && tabs.includes(tab)) {
  //     setActiveTab(tab);
  //   } else {
  //     setActiveTab("0");
  //   }
  // }, [user]);

  // const tabChange = (newTab) => {
  //   if (activeTab !== newTab) {
  //     setActiveTab(newTab);
  //     searchParams.set("tab", newTab);
  //     navigate({ search: searchParams.toString() });
  //   }
  // };

  // //#region profil düzenleme
  // const profileValidation = useFormik({
  //   enableReinitialize: true,

  //   initialValues: {
  //     id: user.id || "",
  //     nameSurname: user.nameSurname || "",
  //     email: user.email || "",
  //     userName: user.userName || "",
  //     phoneNumber: user.phoneNumber || "",
  //     isActive: user.isActive,
  //     userRole: user.userRole || "",
  //   },

  //   validationSchema: Yup.object({
  //     nameSurname: Yup.string().required("Lütfen ad soyad giriniz"),
  //   }),

  //   onSubmit: async (values) => {
  //     const resultAction = await dispatch(updateUser(values));

  //     if (resultAction.meta.requestStatus === "fulfilled") {
  //       setIsProfileEdit(false);
  //     }
  //   },
  // });

  // // ** ilk harf büyük yapma fonksiyonları
  // const capitalizeFirstLetter = (str) => {
  //   return str.replace(/^(.)|\s+(.)/g, function ($1) {
  //     return $1.toLocaleUpperCase("tr");
  //   });
  // };

  // const handleChange = (validationObject) => (e) => {
  //   const { name, value } = e.target;
  //   const capitalizedValue = capitalizeFirstLetter(value);
  //   validationObject.setFieldValue(name, capitalizedValue);
  // };

  // const handleProfileChange = handleChange(profileValidation);

  // //#endregion

  // //#region şifre değiştirme
  // const passwordValidation = useFormik({
  //   enableReinitialize: true,

  //   initialValues: {
  //     userId: user.id,
  //     currentPassword: "",
  //     newPassword: "",
  //     newPasswordAgain: "",
  //   },

  //   validationSchema: Yup.object({
  //     currentPassword: Yup.string()
  //       .required("Lütfen eski şifrenizi girin")
  //       .min(6, "Lütfen eski şifrenizi giriniz"),
  //     newPassword: Yup.string()
  //       .required("Lütfen yeni şifrenizi girin")
  //       .min(6, "Yeni şifreniz en az 6 karakter olmalı")
  //       .matches(/[A-Z]/, "Yeni şifreniz en az bir büyük harf içermeli")
  //       .matches(/[a-z]/, "Yeni şifreniz en az bir küçük harf içermeli")
  //       .matches(/\d/, "Yeni şifreniz en az bir rakam içermeli"),
  //     newPasswordAgain: Yup.string()
  //       .oneOf([Yup.ref("newPassword"), null], "Şifreler eşleşmiyor")
  //       .required("Lütfen yeni şifrenizi tekrar girin"),
  //   }),

  //   onSubmit: async (values) => {
  //     const resultAction = await dispatch(changePassword(values));

  //     if (resultAction.meta.requestStatus === "fulfilled") {
  //       await dispatch(logoutUser());
  //       window.location.reload();
  //     }
  //   },
  // });
  // //#endregion

  // if (userLoading || Object.keys(user).length == 0) {
  //   return (
  //     <Spinner
  //       className="position-absolute top-50 start-50"
  //       animation="border"
  //       color="primary"
  //     />
  //   );
  // }

  document.title = "Profilim | Global Assistance";
  return null;
  // return (
  //   <React.Fragment>
  //     <div className="page-content">
  //       <Container fluid>
  //         <Row>
  //           <Col xxl={3}>
  //             <Card>
  //               <CardBody className="p-4 text-center">
  //                 <div className="text-center">
  //                   <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
  //                     <img
  //                       src={logo}
  //                       className="rounded-circle avatar-xl img-thumbnail user-profile-image"
  //                       alt="user-profile"
  //                       style={{ objectFit: "contain" }}
  //                     />
  //                   </div>
  //                   <h5 className="fs-16 mb-1">{user.nameSurname}</h5>
  //                   <p className="text-muted mb-3">{user.email}</p>
  //                 </div>
  //                 <button
  //                   type="button"
  //                   className="btn btn-primary btn-sm"
  //                   onClick={() => setIsProfileEdit(true)}
  //                   disabled={isProfileEdit}
  //                 >
  //                   Düzenle
  //                 </button>
  //               </CardBody>
  //             </Card>
  //           </Col>

  //           <Col xxl={9}>
  //             <Card>
  //               <CardHeader>
  //                 <Nav
  //                   className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
  //                   role="tablist"
  //                 >
  //                   <NavItem>
  //                     <NavLink
  //                       className={classnames({
  //                         active: activeTab === tabs[0],
  //                       })}
  //                       onClick={() => {
  //                         tabChange("0");
  //                       }}
  //                       type="button"
  //                     >
  //                       Profil Bilgileri
  //                     </NavLink>
  //                   </NavItem>
  //                   <NavItem>
  //                     <NavLink
  //                       className={classnames({
  //                         active: activeTab === tabs[1],
  //                       })}
  //                       onClick={() => {
  //                         tabChange("1");
  //                       }}
  //                       type="button"
  //                     >
  //                       Şifre Değiştir
  //                     </NavLink>
  //                   </NavItem>
  //                 </Nav>
  //               </CardHeader>
  //               <CardBody className="p-4">
  //                 <TabContent activeTab={activeTab}>
  //                   <TabPane tabId={tabs[0]}>
  //                     <Form
  //                       onSubmit={(e) => {
  //                         e.preventDefault();
  //                         profileValidation.handleSubmit();
  //                         return false;
  //                       }}
  //                     >
  //                       <Row>
  //                         <Col lg={12}>
  //                           <div className="mb-3">
  //                             <Label
  //                               htmlFor="nameSurname"
  //                               className="form-label"
  //                             >
  //                               Ad Soyad
  //                             </Label>
  //                             <Input
  //                               disabled={!isProfileEdit}
  //                               id="nameSurname"
  //                               name="nameSurname"
  //                               className="form-control"
  //                               placeholder="Adınız"
  //                               type="text"
  //                               autoComplete="off"
  //                               onChange={handleProfileChange}
  //                               onBlur={profileValidation.handleBlur}
  //                               value={
  //                                 profileValidation.values.nameSurname || ""
  //                               }
  //                               invalid={
  //                                 profileValidation.touched.nameSurname &&
  //                                 profileValidation.errors.nameSurname
  //                                   ? true
  //                                   : false
  //                               }
  //                             />
  //                             {profileValidation.touched.nameSurname &&
  //                               profileValidation.errors.nameSurname && (
  //                                 <FormFeedback type="invalid">
  //                                   {profileValidation.errors.nameSurname}
  //                                 </FormFeedback>
  //                               )}
  //                           </div>
  //                         </Col>
  //                         <Col lg={6}>
  //                           <div className="mb-3">
  //                             <Label
  //                               className="form-label"
  //                               htmlFor="phoneNumber"
  //                             >
  //                               Telefon
  //                             </Label>
  //                             <InputGroup>
  //                               <span
  //                                 className="input-group-text "
  //                                 style={{
  //                                   width: "44px",
  //                                   justifyContent: "center",
  //                                 }}
  //                                 id="inputGroupPrepend2"
  //                               >
  //                                 0
  //                               </span>
  //                               <ReactInputMask
  //                                 disabled
  //                                 id="phoneNumber"
  //                                 name="phoneNumber"
  //                                 mask="599 999 99 99"
  //                                 maskChar=" "
  //                                 alwaysShowMask
  //                                 className={"form-control"}
  //                                 value={user.phoneNumber || ""}
  //                               />
  //                             </InputGroup>
  //                           </div>
  //                         </Col>
  //                         <Col lg={6}>
  //                           <div className="mb-3">
  //                             <Label htmlFor="email" className="form-label">
  //                               E-posta
  //                             </Label>
  //                             <Input
  //                               disabled
  //                               autoComplete="off"
  //                               id="email"
  //                               name="email"
  //                               className="form-control"
  //                               placeholder="E-posta adresiniz"
  //                               type="text"
  //                               value={user.email || ""}
  //                             />
  //                           </div>
  //                         </Col>
  //                         <Col lg={12}>
  //                           <div className="hstack gap-2 justify-content-end">
  //                             {isProfileEdit && (
  //                               <>
  //                                 <button
  //                                   className="btn btn-light btn-sm"
  //                                   onClick={() => {
  //                                     setIsProfileEdit(false);
  //                                     profileValidation.resetForm();
  //                                   }}
  //                                 >
  //                                   İptal
  //                                 </button>
  //                                 <button
  //                                   type="button"
  //                                   className="btn btn-primary btn-sm"
  //                                   onClick={() => {
  //                                     if (!userLoading) {
  //                                       profileValidation.handleSubmit();
  //                                     }
  //                                   }}
  //                                 >
  //                                   Kaydet
  //                                 </button>
  //                               </>
  //                             )}
  //                           </div>
  //                         </Col>
  //                       </Row>
  //                     </Form>
  //                   </TabPane>

  //                   <TabPane tabId={tabs[1]}>
  //                     <Form
  //                       onSubmit={(e) => {
  //                         e.preventDefault();
  //                         passwordValidation.handleSubmit();
  //                         return false;
  //                       }}
  //                     >
  //                       <Row className="g-2">
  //                         <Col lg={4}>
  //                           <div>
  //                             <Label
  //                               htmlFor="currentPassword"
  //                               className="form-label"
  //                             >
  //                               Eski Şifre
  //                             </Label>
  //                             <Input
  //                               id="currentPassword"
  //                               name="currentPassword"
  //                               value={
  //                                 passwordValidation.values.currentPassword
  //                               }
  //                               type="password"
  //                               className="form-control pe-5"
  //                               placeholder="Eski şifreniz"
  //                               onChange={passwordValidation.handleChange}
  //                               onBlur={passwordValidation.handleBlur}
  //                               invalid={
  //                                 passwordValidation.touched.currentPassword &&
  //                                 passwordValidation.errors.currentPassword
  //                                   ? true
  //                                   : false
  //                               }
  //                             />
  //                             {passwordValidation.touched.currentPassword &&
  //                               passwordValidation.errors.currentPassword && (
  //                                 <FormFeedback type="invalid">
  //                                   {passwordValidation.errors.currentPassword}
  //                                 </FormFeedback>
  //                               )}
  //                           </div>
  //                         </Col>
  //                         <Col lg={4}>
  //                           <div>
  //                             <Label
  //                               htmlFor="newPassword"
  //                               className="form-label"
  //                             >
  //                               Yeni Şifre
  //                             </Label>
  //                             <InputGroup>
  //                               <Input
  //                                 id="newPassword"
  //                                 name="newPassword"
  //                                 value={passwordValidation.values.newPassword}
  //                                 type="password"
  //                                 className="form-control pe-5"
  //                                 placeholder="Yeni şifreniz"
  //                                 onChange={passwordValidation.handleChange}
  //                                 onBlur={passwordValidation.handleBlur}
  //                                 invalid={
  //                                   passwordValidation.touched.newPassword &&
  //                                   passwordValidation.errors.newPassword
  //                                     ? true
  //                                     : false
  //                                 }
  //                               />
  //                             </InputGroup>
  //                             {passwordValidation.touched.newPassword &&
  //                               passwordValidation.errors.newPassword && (
  //                                 <div className="mt-2">
  //                                   {passwordValidation.values.newPassword
  //                                     .length < 6 && (
  //                                     <div
  //                                       type="invalid"
  //                                       className="d-block invalid-feedback"
  //                                     >
  //                                       En az 6 karakter
  //                                     </div>
  //                                   )}
  //                                   {!/[A-Z]/.test(
  //                                     passwordValidation.values.newPassword
  //                                   ) && (
  //                                     <div
  //                                       type="invalid"
  //                                       className="d-block invalid-feedback"
  //                                     >
  //                                       Büyük harf içermeli
  //                                     </div>
  //                                   )}
  //                                   {!/[a-z]/.test(
  //                                     passwordValidation.values.newPassword
  //                                   ) && (
  //                                     <div
  //                                       type="invalid"
  //                                       className="d-block invalid-feedback"
  //                                     >
  //                                       Küçük harf içermeli
  //                                     </div>
  //                                   )}
  //                                   {!/\d/.test(
  //                                     passwordValidation.values.newPassword
  //                                   ) && (
  //                                     <div
  //                                       type="invalid"
  //                                       className="d-block invalid-feedback"
  //                                     >
  //                                       Sayı içermeli
  //                                     </div>
  //                                   )}
  //                                 </div>
  //                               )}
  //                           </div>
  //                         </Col>
  //                         <Col lg={4}>
  //                           <div>
  //                             <Label
  //                               htmlFor="newPasswordAgain"
  //                               className="form-label"
  //                             >
  //                               Şifre Tekrarı
  //                             </Label>
  //                             <Input
  //                               id="newPasswordAgain"
  //                               name="newPasswordAgain"
  //                               value={
  //                                 passwordValidation.values.newPasswordAgain
  //                               }
  //                               type="password"
  //                               className="form-control pe-5"
  //                               placeholder="Şifrenizi doğrulayın"
  //                               onChange={passwordValidation.handleChange}
  //                               onBlur={passwordValidation.handleBlur}
  //                               invalid={
  //                                 passwordValidation.touched.newPasswordAgain &&
  //                                 passwordValidation.errors.newPasswordAgain
  //                                   ? true
  //                                   : false
  //                               }
  //                             />
  //                             {passwordValidation.touched.newPasswordAgain &&
  //                               passwordValidation.errors.newPasswordAgain && (
  //                                 <FormFeedback type="invalid">
  //                                   {passwordValidation.errors.newPasswordAgain}
  //                                 </FormFeedback>
  //                               )}
  //                           </div>
  //                         </Col>
  //                         <Col lg={12} className="mt-3">
  //                           <div className="hstack gap-2 justify-content-end">
  //                             <button
  //                               type="button"
  //                               className="btn btn-primary btn-sm"
  //                               onClick={() => {
  //                                 passwordValidation.handleSubmit();
  //                               }}
  //                             >
  //                               Şifremi Değiştir
  //                             </button>
  //                           </div>
  //                         </Col>
  //                         <Col lg={12}>
  //                           <p className="text-muted fs-12">
  //                             *Şifrenizi değiştirdiğinizde, güvenliğiniz için
  //                             yeniden giriş yapmanız gerekecek.
  //                           </p>
  //                         </Col>
  //                       </Row>
  //                     </Form>
  //                   </TabPane>
  //                 </TabContent>
  //               </CardBody>
  //             </Card>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //   </React.Fragment>
  // );
};

export default Profile;
