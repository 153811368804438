import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createAgency } from "../../slices/apps/agencySlices";

// router
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp = /^[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}$/;
const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;

// data dosyaları
import CityJson from "../../common/data/city.json";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler.map((ilce) => ({
    label: ilce.ilce_adi,
    value: ilce.ilce_adi,
  })),
}));

const UserAddModal = ({ modal, setModal }) => {
  const dispatch = useDispatch();

  const [passwordShow, setPasswordShow] = useState(false);

  const { roles, agencies, createUserLoading } = useSelector((state) => {
    return {
      roles: state.user.roles,
      agencies: state.agency.agencies,
      createUserLoading: state.user.createUserLoading,
    };
  }, shallowEqual);

  useEffect(() => {
    setModal(modal);

    if (modal) {
      userValidation.resetForm();
    }
  }, [modal]);

  const userValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      nameSurname: "",
      password: "",
      agencyId: 0,
      userRole: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      nameSurname: Yup.string().required("Lütfen ad soyad girin"),
      password: Yup.string()
        .required("Lütfen şifre girin")
        .min(6, "Lütfen şifre girin"),
    }),

    onSubmit: async (values) => {
      console.log(values);

      // const resultAction = await dispatch(createAgency(data));

      // if (resultAction.meta.requestStatus === "fulfilled") {
      //   setModal(false);
      // }
    },
  });

  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={() => {
        setModal(false);
      }}
    >
      <ModalHeader
        className="modal-title "
        id="myLargeModalLabel"
        toggle={() => {
          setModal(false);
        }}
      >
        Yeni Kullanıcı Ekle
      </ModalHeader>

      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            userValidation.handleSubmit();
            return false;
          }}
          action="#"
          autoComplete="off"
        >
          <Row className="align-items-start">
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="nameSurname" className="form-label">
                  Ad Soyad
                </Label>
                <Input
                  name="nameSurname"
                  className="form-control"
                  placeholder="Ad Soyad"
                  type="text"
                  autoComplete="off"
                  onChange={userValidation.handleChange}
                  onBlur={userValidation.handleBlur}
                  value={userValidation.values.nameSurname || ""}
                  invalid={
                    userValidation.touched.nameSurname &&
                    userValidation.errors.nameSurname
                      ? true
                      : false
                  }
                />
                {userValidation.touched.nameSurname &&
                  userValidation.errors.nameSurname && (
                    <FormFeedback type="invalid">
                      {userValidation.errors.nameSurname}
                    </FormFeedback>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  E-posta Adresi
                </Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="E-posta Adresi"
                  type="text"
                  autoComplete="off"
                  onChange={userValidation.handleChange}
                  onBlur={userValidation.handleBlur}
                  value={userValidation.values.email || ""}
                  invalid={
                    userValidation.touched.email && userValidation.errors.email
                      ? true
                      : false
                  }
                />
                {userValidation.touched.email &&
                  userValidation.errors.email && (
                    <FormFeedback type="invalid">
                      {userValidation.errors.email}
                    </FormFeedback>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="password-input">
                  Şifre
                </Label>
                <InputGroup>
                  <span
                    className="input-group-text"
                    style={{ width: "44px" }}
                    id="inputGroupPrepend2"
                  >
                    <i
                      onClick={() => setPasswordShow(!passwordShow)}
                      style={{
                        cursor: "pointer",
                        color: "gray",
                      }}
                      className={
                        passwordShow
                          ? "ri-eye-off-fill align-middle"
                          : "ri-eye-fill align-middle"
                      }
                    ></i>
                  </span>
                  <Input
                    name="password"
                    value={userValidation.values.password || ""}
                    type={passwordShow ? "text" : "password"}
                    className="form-control pe-5"
                    placeholder="Şifreniz"
                    onChange={userValidation.handleChange}
                    onBlur={userValidation.handleBlur}
                    invalid={
                      userValidation.touched.password &&
                      userValidation.errors.password
                        ? true
                        : false
                    }
                  />
                </InputGroup>
                {userValidation.touched.password &&
                  userValidation.errors.password && (
                    <div type="invalid" className="d-block invalid-feedback">
                      {userValidation.errors.password}
                    </div>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="userRoleSelect" className="form-label">
                  Rol
                </Label>
                <Select
                  placeholder="Rol seçiniz"
                  id="userRoleSelect"
                  name="userRole"
                  options={roles.map((role) => ({
                    label: role,
                    value: role,
                  }))}
                  value={roles
                    .map((role) => ({
                      label: role,
                      value: role,
                    }))
                    .find(
                      (item) => item.value === userValidation.values.userRole
                    )}
                  onChange={(value) => {
                    userValidation.setFieldValue("userRole", value.value);
                  }}
                  onBlur={userValidation.handleBlur}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        userValidation.touched.userRole &&
                        userValidation.errors.userRole &&
                        !state.isFocused
                          ? "#e30a17"
                          : "#e9ebec",
                    }),
                  }}
                />
                {userValidation.touched.userRole &&
                  userValidation.errors.userRole && (
                    <div className="d-block invalid-feedback">
                      {userValidation.errors.userRole}
                    </div>
                  )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="agencyIdSelect" className="form-label">
                  Acente
                </Label>
                <Select
                  placeholder="Acente seçiniz"
                  id="agencyIdSelect"
                  name="agencyId"
                  options={agencies.map((item) => ({
                    label: item.companyName,
                    value: item.id,
                  }))}
                  value={agencies
                    .map((item) => ({
                      label: item.companyName,
                      value: item.id,
                    }))
                    .find(
                      (item) => item.value === userValidation.values.agencyId
                    )}
                  onChange={(value) => {
                    userValidation.setFieldValue("agencyId", value.value);
                  }}
                  onBlur={userValidation.handleBlur}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor:
                        userValidation.touched.agencyId &&
                        userValidation.errors.agencyId &&
                        !state.isFocused
                          ? "#e30a17"
                          : "#e9ebec",
                    }),
                  }}
                />
                {userValidation.touched.agencyId &&
                  userValidation.errors.agencyId && (
                    <div className="d-block invalid-feedback">
                      {userValidation.errors.agencyId}
                    </div>
                  )}
              </div>
            </Col>
          </Row>

          <div className="d-flex align-items-center gap-3 mt-4">
            <Button
              className="btn btn-sm ms-auto nexttab nexttab"
              color="primary"
              type="submit"
              disabled={createUserLoading}
            >
              Kaydet
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UserAddModal;
