import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Authentication
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Pages
import Agencies from "../pages/Agency";
import Announcements from "../pages/Announcement";
import Profile from "../pages/Profile";
import Package from "../pages/Package";
import Ticket from "../pages/Ticket";
import Pay from "../pages/Pay";
import Customers from "../pages/Customer";
import PackageAdmin from "../pages/PackageAdmin";
import PackageAdd from "../pages/PackageAdmin/PackageAdd";
import PackageUpdate from "../pages/PackageAdmin/PackageUpdate";
import Users from "../pages/User";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    roles: ["SuperAdmin", "Reseller"],
    component: <Dashboard />,
  },
  {
    path: "/",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Navigate to="/dashboard" />,
  },

  {
    path: "/profile",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Profile />,
  },

  {
    path: "/agencies",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Agencies />,
  },

  {
    path: "/announcements",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Announcements />,
  },

  {
    path: "/customers",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Customers />,
  },

  {
    path: "/users",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Users />,
  },

  {
    path: "/packages",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Package />,
  },

  {
    path: "/package-admin",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <PackageAdmin />,
  },

  {
    path: "/package-admin/create",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <PackageAdd />,
  },

  {
    path: "/package-admin/update",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <PackageUpdate />,
  },

  {
    path: "/pay",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Pay />,
  },

  {
    path: "/ticket",
    exact: true,
    roles: ["SuperAdmin", "Reseller"],
    component: <Ticket />,
  },

  //Pages
  // { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "*", component: <Navigate to="/404" /> },
];

export { authProtectedRoutes, publicRoutes };
