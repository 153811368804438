import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../slices/apps/customerSlice";
import { fetchAgencies } from "../../slices/apps/agencySlices";

// sayfalar, modallar vb.
import CustomerTable from "./CustomerTable";

const Customers = () => {
  const dispatch = useDispatch();

  const { customersRefresh } = useSelector((state) => {
    return {
      customersRefresh: state.agency.customersRefresh,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchCustomers());
      await dispatch(fetchAgencies());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, customersRefresh]);

  document.title = "Müşteri Listesi | Global Assistance";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <CustomerTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
