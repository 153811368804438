import { combineReducers } from "redux";

// Front
import LayoutReducer from "./other/layouts/reducer";

import authSlice from "../slices/apps/authSlice";
import agencySlice from "./apps/agencySlices";
import customerSlice from "./apps/customerSlice";
import packageSlice from "./apps/packageSlice";
import userSlice from "./apps/userSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,

  auth: authSlice,
  agency: agencySlice,
  customer: customerSlice,
  package: packageSlice,
  user: userSlice,
});

export default rootReducer;
