import axios from "axios";

const baseApi = axios.create({
  baseURL: "https://api.globalassistance.com.tr/",
  // baseURL: "http://192.168.50.12:68/",
});

baseApi.interceptors.request.use(
  (config) => {
    const data = localStorage.getItem("authUser");
    const token = JSON.parse(data)?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default baseApi;
