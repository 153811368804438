import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAgency, updateAgency } from "../../slices/apps/agencySlices";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// regex, değişkenler
const phoneRegExp = /^[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}$/;
const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;

// data dosyaları
import CityJson from "../../common/data/city.json";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler.map((ilce) => ({
    label: ilce.ilce_adi,
    value: ilce.ilce_adi,
  })),
}));

const AgencyUpdateModal = ({ id }) => {
  const dispatch = useDispatch();

  // states
  const [modal, setModal] = useState(false);

  const { agency, updateAgencyLoading } = useSelector((state) => {
    return {
      agency: state.agency.agency,
      updateAgencyLoading: state.agency.updateAgencyLoading,
    };
  }, shallowEqual);

  // states
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [progressbarvalue, setprogressbarvalue] = useState(0);

  const fetchData = async () => {
    try {
      const resultAction = await dispatch(fetchAgency(id));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setModal(true);

        setactiveTab(1);
        setPassedSteps([1]);
        setprogressbarvalue(0);

        agencyValidation.resetForm();
        contactValidation.resetForm();
        bankValidation.resetForm();
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  function tog_large(e) {
    if (e) {
      fetchData();
    } else setModal(false);
  }

  // Acente validation
  const agencyValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: id,
      companyName: agency?.CompanyName,
      companyCode: agency?.CompanyCode,
      taxNumber: agency?.TaxNumber,
      taxOffice: agency?.TaxOffice,
    },

    validationSchema: Yup.object({
      companyName: Yup.string().required("Lütfen firma adı girin"),
      companyCode: Yup.string().required("Lütfen firma kodu girin"),
      taxNumber: Yup.string()
        .matches(/^[0-9]+$/, "Vergi numarası sadece sayılardan oluşmalıdır")
        .min(10, "Vergi numarası en az 10 karakter olmalıdır")
        .max(11, "Vergi numarası en fazla 11 karakter olmalıdır")
        .required("Lütfen vergi numarası girin"),
      taxOffice: Yup.string().required("Lütfen vergi dairesi girin"),
    }),

    onSubmit: async () => {
      toggleTab(2, 50);
    },
  });

  const contactValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      phoneNumber: agency?.PhoneNumber,
      email: agency?.Email,
      address: agency?.Address,
      city: agency?.City,
      district: agency?.District,
      postCode: agency?.PostCode,
    },

    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .required("Lütfen e-posta adresi girin"),
      address: Yup.string()
        .required("Lütfen adres bilgisi girin")
        .min(2, "Lütfen adres bilgisi girin"),
      city: Yup.string().required("Lütfen il seçin"),
      district: Yup.string().required("Lütfen ilçe seçin"),
      postCode: Yup.string(),
    }),

    onSubmit: async () => {
      toggleTab(3, 100);
    },
  });

  const bankValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      bankIban: agency?.BankIban,
      bankName: agency?.BankName,
    },

    validationSchema: Yup.object({
      bankIban: Yup.string()
        .required("Lütfen IBAN girin")
        .matches(ibanRegex, "Geçerli bir IBAN girin"),
      bankName: Yup.string().required("Lütfen banka adı girin"),
    }),

    onSubmit: async (values) => {
      const data = {
        ...agencyValidation.values,
        ...contactValidation.values,
        ...values,
      };

      const resultAction = await dispatch(updateAgency(data));

      if (resultAction.meta.requestStatus === "fulfilled") {
        tog_large(false);
      }
    },
  });

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  return (
    <Fragment>
      <i
        id={`company-tooltipTop-${id}`}
        className="bx bxs-edit text-dark"
        style={{
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={() => tog_large(true)}
      />

      <UncontrolledTooltip placement="top" target={`company-tooltipTop-${id}`}>
        Acente Bilgilerini Düzenle
      </UncontrolledTooltip>

      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title "
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          Acente Bilgilerini Düzenle
        </ModalHeader>

        <ModalBody>
          <div className="progress-nav mb-4">
            <Progress value={progressbarvalue} style={{ height: "1px" }} />

            <Nav
              className="nav-pills progress-bar-tab custom-nav z-1 position-relative"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  to="#"
                  id="pills-gen-info-tab"
                  disabled={updateAgencyLoading}
                  className={classnames(
                    {
                      active: activeTab === 1,
                      done: activeTab <= 4 && activeTab >= 0,
                    },
                    "rounded-pill"
                  )}
                  onClick={() => {
                    toggleTab(1, 0);
                  }}
                  tag="button"
                  type="submit"
                >
                  1
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  id="pills-gen-info-tab"
                  disabled={updateAgencyLoading}
                  className={classnames(
                    {
                      active: activeTab === 2,
                      done: activeTab <= 4 && activeTab > 1,
                    },
                    "rounded-pill"
                  )}
                  onClick={() => {
                    agencyValidation.handleSubmit();
                  }}
                  tag="button"
                  type="submit"
                >
                  2
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  id="pills-gen-info-tab"
                  disabled={updateAgencyLoading}
                  className={classnames(
                    {
                      active: activeTab === 3,
                      done: activeTab <= 4 && activeTab > 2,
                    },
                    "rounded-pill"
                  )}
                  onClick={() => {
                    contactValidation.handleSubmit();
                  }}
                  tag="button"
                  type="submit"
                >
                  3
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  agencyValidation.handleSubmit();
                  return false;
                }}
                action="#"
                autoComplete="off"
              >
                <Row className="align-items-start">
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="taxNumber">
                        Vergi Numarası
                      </Label>
                      <InputGroup>
                        <ReactInputMask
                          mask="99999999999"
                          maskChar=" "
                          name="taxNumber"
                          alwaysShowMask
                          inputMode="numeric"
                          type="tel"
                          className={
                            agencyValidation.touched.taxNumber &&
                            agencyValidation.errors.taxNumber
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={agencyValidation.handleChange}
                          value={agencyValidation.values.taxNumber || ""}
                        />
                      </InputGroup>
                      {agencyValidation.touched.taxNumber &&
                        agencyValidation.errors.taxNumber && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {agencyValidation.errors.taxNumber}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="taxOffice" className="form-label">
                        Vergi Dairesi
                      </Label>
                      <Input
                        name="taxOffice"
                        className="form-control"
                        placeholder="Vergi Dairesi"
                        type="text"
                        autoComplete="off"
                        onChange={agencyValidation.handleChange}
                        onBlur={agencyValidation.handleBlur}
                        value={agencyValidation.values.taxOffice || ""}
                        invalid={
                          agencyValidation.touched.taxOffice &&
                          agencyValidation.errors.taxOffice
                            ? true
                            : false
                        }
                      />
                      {agencyValidation.touched.taxOffice &&
                        agencyValidation.errors.taxOffice && (
                          <FormFeedback type="invalid">
                            {agencyValidation.errors.taxOffice}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="companyName" className="form-label">
                        Firma Adı
                      </Label>
                      <Input
                        name="companyName"
                        className="form-control"
                        placeholder="Firma Adı"
                        type="text"
                        autoComplete="off"
                        onChange={agencyValidation.handleChange}
                        onBlur={agencyValidation.handleBlur}
                        value={agencyValidation.values.companyName || ""}
                        invalid={
                          agencyValidation.touched.companyName &&
                          agencyValidation.errors.companyName
                            ? true
                            : false
                        }
                      />
                      {agencyValidation.touched.companyName &&
                        agencyValidation.errors.companyName && (
                          <FormFeedback type="invalid">
                            {agencyValidation.errors.companyName}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="companyCode" className="form-label">
                        Firma Kodu
                      </Label>
                      <Input
                        name="companyCode"
                        className="form-control"
                        placeholder="Firma Kodu"
                        type="text"
                        autoComplete="off"
                        onChange={agencyValidation.handleChange}
                        onBlur={agencyValidation.handleBlur}
                        value={agencyValidation.values.companyCode || ""}
                        invalid={
                          agencyValidation.touched.companyCode &&
                          agencyValidation.errors.companyCode
                            ? true
                            : false
                        }
                      />
                      {agencyValidation.touched.companyCode &&
                        agencyValidation.errors.companyCode && (
                          <FormFeedback type="invalid">
                            {agencyValidation.errors.companyCode}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <div className="d-flex align-items-center gap-3 mt-4">
                  <Button
                    className="btn btn-sm ms-auto nexttab nexttab"
                    color="primary"
                    type="submit"
                    disabled={updateAgencyLoading}
                  >
                    Devam Et
                  </Button>
                </div>
              </Form>
            </TabPane>

            <TabPane tabId={2}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  contactValidation.handleSubmit();
                  return false;
                }}
                action="#"
                autoComplete="off"
              >
                <Row className="align-items-start">
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="email" className="form-label">
                        E-posta Adresi
                      </Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Kullanıcı E-posta Adresi"
                        type="text"
                        autoComplete="off"
                        onChange={contactValidation.handleChange}
                        onBlur={contactValidation.handleBlur}
                        value={contactValidation.values.email || ""}
                        invalid={
                          contactValidation.touched.email &&
                          contactValidation.errors.email
                            ? true
                            : false
                        }
                      />
                      {contactValidation.touched.email &&
                        contactValidation.errors.email && (
                          <FormFeedback type="invalid">
                            {contactValidation.errors.email}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="phoneNumber">
                        Telefon Numarası
                      </Label>
                      <InputGroup>
                        <span
                          className="input-group-text "
                          style={{
                            width: "44px",
                            justifyContent: "center",
                          }}
                          id="inputGroupPrepend2"
                        >
                          0
                        </span>
                        <ReactInputMask
                          mask="999 999 99 99"
                          maskChar=" "
                          name="phoneNumber"
                          alwaysShowMask
                          className={
                            contactValidation.touched.phoneNumber &&
                            contactValidation.errors.phoneNumber
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={contactValidation.handleChange}
                          value={contactValidation.values.phoneNumber || ""}
                        />
                      </InputGroup>
                      {contactValidation.touched.phoneNumber &&
                        contactValidation.errors.phoneNumber && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {contactValidation.errors.phoneNumber}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="citySelect" className="form-label">
                        İl
                      </Label>
                      <Select
                        placeholder="İl seçiniz"
                        id="citySelect"
                        name="city"
                        options={city}
                        value={
                          city.find(
                            (item) =>
                              item.value === contactValidation.values.city
                          ) || ""
                        }
                        onChange={(value) => {
                          contactValidation.setFieldValue("city", value.value);
                          contactValidation.setFieldValue("district", null);
                        }}
                        onBlur={contactValidation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              contactValidation.touched.city &&
                              contactValidation.errors.city &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {contactValidation.touched.city &&
                        contactValidation.errors.city && (
                          <div className="d-block invalid-feedback">
                            {contactValidation.errors.city}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="districtSelect" className="form-label">
                        İlçe
                      </Label>
                      <Select
                        isDisabled={!contactValidation.values.city}
                        placeholder="İlçe seçiniz"
                        id="districtSelect"
                        name="district"
                        options={
                          city.find(
                            (item) =>
                              item.value === contactValidation.values.city
                          )?.districts || []
                        }
                        value={
                          city
                            .find(
                              (item) =>
                                item.value === contactValidation.values.city
                            )
                            ?.districts.find(
                              (district) =>
                                district.value ===
                                contactValidation.values.district
                            ) || ""
                        }
                        onChange={(value) => {
                          contactValidation.setFieldValue(
                            "district",
                            value.value
                          );
                        }}
                        onBlur={contactValidation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              contactValidation.touched.district &&
                              contactValidation.errors.district &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {contactValidation.touched.district &&
                        contactValidation.errors.district && (
                          <div className="d-block invalid-feedback">
                            {contactValidation.errors.district}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="postCode">
                        Posta Kodu
                      </Label>
                      <InputGroup>
                        <ReactInputMask
                          mask="99999"
                          maskChar=" "
                          name="postCode"
                          alwaysShowMask
                          inputMode="numeric"
                          type="tel"
                          className={
                            contactValidation.touched.postCode &&
                            contactValidation.errors.postCode
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={contactValidation.handleChange}
                          value={contactValidation.values.postCode || ""}
                        />
                      </InputGroup>
                      {contactValidation.touched.postCode &&
                        contactValidation.errors.postCode && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {contactValidation.errors.postCode}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="address" className="form-label">
                        Adres
                      </Label>
                      <Input
                        name="address"
                        className="form-control"
                        placeholder="Mahalle, cadde, sokak..."
                        type="textarea"
                        autoComplete="off"
                        rows="2"
                        onChange={contactValidation.handleChange}
                        onBlur={contactValidation.handleBlur}
                        value={contactValidation.values.address || ""}
                        invalid={
                          contactValidation.touched.address &&
                          contactValidation.errors.address
                            ? true
                            : false
                        }
                        style={{ resize: "none" }}
                      />
                      {contactValidation.touched.address &&
                        contactValidation.errors.address && (
                          <FormFeedback type="invalid">
                            {contactValidation.errors.address}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <div className="d-flex align-items-center gap-3 mt-4">
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-label previestab"
                    onClick={() => {
                      toggleTab(1, 0);
                    }}
                  >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                    Önceki Adım
                  </button>
                  <Button
                    className="btn btn-sm ms-auto nexttab nexttab"
                    color="primary"
                    type="submit"
                    disabled={updateAgencyLoading}
                  >
                    Devam Et
                  </Button>
                </div>
              </Form>
            </TabPane>

            <TabPane tabId={3}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  bankValidation.handleSubmit();
                  return false;
                }}
                action="#"
                autoComplete="off"
              >
                <Row className="align-items-start">
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="bankName" className="form-label">
                        Banka Adı
                      </Label>
                      <Input
                        name="bankName"
                        className="form-control"
                        placeholder="Banka Adı"
                        type="text"
                        autoComplete="off"
                        onChange={bankValidation.handleChange}
                        onBlur={bankValidation.handleBlur}
                        value={bankValidation.values.bankName || ""}
                        invalid={
                          bankValidation.touched.bankName &&
                          bankValidation.errors.bankName
                            ? true
                            : false
                        }
                      />
                      {bankValidation.touched.bankName &&
                        bankValidation.errors.bankName && (
                          <FormFeedback type="invalid">
                            {bankValidation.errors.bankName}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="bankIban" className="form-label">
                        IBAN
                      </Label>
                      <Input
                        name="bankIban"
                        className="form-control"
                        placeholder="IBAN"
                        type="text"
                        autoComplete="off"
                        onChange={bankValidation.handleChange}
                        onBlur={bankValidation.handleBlur}
                        value={bankValidation.values.bankIban || ""}
                        invalid={
                          bankValidation.touched.bankIban &&
                          bankValidation.errors.bankIban
                            ? true
                            : false
                        }
                      />
                      {bankValidation.touched.bankIban &&
                        bankValidation.errors.bankIban && (
                          <FormFeedback type="invalid">
                            {bankValidation.errors.bankIban}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                <div className="d-flex align-items-center gap-3 mt-4">
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-label previestab"
                    onClick={() => {
                      toggleTab(activeTab - 1, 50);
                    }}
                  >
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                    Önceki Adım
                  </button>
                  <Button
                    className="btn btn-sm ms-auto nexttab nexttab"
                    color="primary"
                    type="submit"
                    disabled={updateAgencyLoading}
                  >
                    Kaydet
                  </Button>
                </div>
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AgencyUpdateModal;
