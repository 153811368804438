import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";

const NotFound = () => {
  const navigate = useNavigate();

  // kullanıcı girişi yoksa giriş sayfasına yönlendirir
  useEffect(() => {
    if (!localStorage.getItem("authUser")) {
      navigate("/login");
    }
  }, []);

  document.title = "Sayfa Bulunamadı | Global Assistance";
  return (
    <React.Fragment>
      <div className="auth-page-content" style={{ background: "white" }}>
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl={7} lg={8}>
                  <div className="text-center">
                    <img
                      src="/images/common/404hd.gif"
                      alt="page not found img"
                      className="img-fluid"
                    />
                    <div className="mt-3">
                      <Link to="/" className="btn btn-dark">
                        <i className="mdi mdi-home me-1"></i>Ana Sayfaya dön
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
