import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  agency: {},
  agencyLoading: true,
  agencyRefresh: true,

  agencies: [],
  agenciesLoading: true,
  agenciesRefresh: true,

  createAgencyLoading: false,
  updateAgencyLoading: false,
  deleteAgencyLoading: false,
};

export const fetchAgency = createAsyncThunk(
  "agency/fetchAgency",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Yükleniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.get(`/Agency/${id}`);

      toast.dismiss();

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.Error.Description);
    }
  }
);

export const fetchAgencies = createAsyncThunk(
  "agency/fetchAgencies",
  async () => {
    try {
      const response = await baseApi.get(`/Agency`);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.Error.Description);
    }
  }
);

export const createAgency = createAsyncThunk(
  "agency/createAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      companyCode: values.companyCode,
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: values.address,
      city: values.city,
      district: values.district,
      postCode: values.postCode,
      taxNumber: values.taxNumber,
      taxOffice: values.taxOffice,
      bankIban: values.bankIban,
      bankName: values.bankName,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Agency`, data);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.Error.Description);
    }
  }
);

export const updateAgency = createAsyncThunk(
  "agency/updateAgency",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      companyCode: values.companyCode,
      companyName: values.companyName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      address: values.address,
      city: values.city,
      district: values.district,
      postCode: values.postCode,
      taxNumber: values.taxNumber,
      taxOffice: values.taxOffice,
      bankIban: values.bankIban,
      bankName: values.bankName,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Agency/${values.id}`, data);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.Error.Description);
    }
  }
);

export const deleteAgency = createAsyncThunk(
  "agency/deleteAgency",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Siliniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Agency/${id}`);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.Error.Description);
    }
  }
);

export const agencySlice = createSlice({
  name: "agency",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgency.pending, (state, action) => {
        state.agency = {};
        state.agencyLoading = true;
      })
      .addCase(fetchAgency.fulfilled, (state, action) => {
        state.agency = action.payload;
        state.agencyLoading = false;
      })
      .addCase(fetchAgency.rejected, (state, action) => {
        state.agency = {};
        state.agencyLoading = false;
      });

    builder
      .addCase(fetchAgencies.pending, (state, action) => {
        state.agencies = [];
        state.agenciesLoading = true;
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.agencies = action.payload;
        state.agenciesLoading = false;
      })
      .addCase(fetchAgencies.rejected, (state, action) => {
        state.agencies = [];
        state.agenciesLoading = false;
      });

    builder
      .addCase(createAgency.pending, (state, action) => {
        state.createAgencyLoading = true;
      })
      .addCase(createAgency.fulfilled, (state, action) => {
        state.createAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.createAgencyLoading = false;
      });

    builder
      .addCase(updateAgency.pending, (state, action) => {
        state.updateAgencyLoading = true;
      })
      .addCase(updateAgency.fulfilled, (state, action) => {
        state.updateAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
      })
      .addCase(updateAgency.rejected, (state, action) => {
        state.updateAgencyLoading = false;
      });

    builder
      .addCase(deleteAgency.pending, (state, action) => {
        state.deleteAgencyLoading = true;
      })
      .addCase(deleteAgency.fulfilled, (state, action) => {
        state.deleteAgencyLoading = false;
        state.agenciesRefresh = !state.agenciesRefresh;
      })
      .addCase(deleteAgency.rejected, (state, action) => {
        state.deleteAgencyLoading = false;
      });
  },
});

export default agencySlice.reducer;
