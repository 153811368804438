import React, { useEffect } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";
import { authCheck, logoutUser } from "../slices/apps/authSlice";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { userProfile, loading, token } = useProfile();

  const { authCheckLoading } = useSelector((state) => {
    return {
      authCheckLoading: state.auth.authCheckLoading,
    };
  }, shallowEqual);

  useEffect(() => {
    if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  useEffect(() => {
    const func = async () => {
      try {
        await dispatch(authCheck());
      } catch (error) {
        dispatch(logoutUser());
      }
    };

    if (token) {
      func();
    } else {
      dispatch(logoutUser());
    }
  }, []);

  if (!userProfile && loading && !token) {
    return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
  }

  const { roles } = props.route;
  const path = props.route.path;

  const isVerify =
    roles?.includes(userProfile?.data?.Role) ||
    userProfile?.data?.Role === "SuperAdmin";

  if (loading || !userProfile || authCheckLoading) {
    return (
      <div id="preloader">
        <div id="status">
          <img
            src="./logo512.png"
            className="avatar-xxl"
            alt="logo"
            style={{ objectFit: "contain" }}
          />
          <div
            className="spinner-border text-secondary avatar-xs mt-3"
            role="status"
          >
            <span className="visually-hidden">Yükleniyor...</span>
          </div>
        </div>
      </div>
    );
  } else if (isVerify) {
    return <>{props.children}</>;
  } else if (path === "*") {
    return <Navigate to="/404" />;
  } else {
    return <Navigate to="/401" />;
  }
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
