import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const Ticket = () => {
  const messageBoxRef = useRef(null);
  const { userProfile } = useProfile();

  const [settings_Menu, setsettings_Menu] = useState(false);
  const [curMessage, setcurMessage] = useState("");

  const [messages, setMessages] = useState([
    {
      id: 0,
      message: "ben k*rt memet",
      role: "User",
      nameSurname: "k*rt MEMET",
    },
    { id: 1, message: "SİKERİM K*RDÜ", role: "Admin", nameSurname: "GEVHER" },
    {
      id: 2,
      message: "ATATÜRK Sinirlendi",
      role: "SuperAdmin",
      nameSurname: "rte",
    },
    {
      id: 3,
      message: "bahçeliyi getirin",
      role: "User",
      nameSurname: "k*rt MEMET",
    },
    {
      id: 4,
      message:
        "asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda asdsad adasda ",
      role: "User",
      nameSurname: "ekrem imaro",
    },
    {
      id: 5,
      message: "bahçeliyi getirin",
      role: "User",
      nameSurname: "k*rt MEMET",
    },
    {
      id: 6,
      message: "bahçeliyi getirin",
      role: "User",
      nameSurname: "k*rt MEMET",
    },
  ]);

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  // Mesaj ekleme işlevi
  const addMessage = () => {
    const newMessage = {
      id: messages.length,
      message: curMessage,
      role: userProfile.data.Role,
      nameSurname: userProfile.data.NameSurname,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setcurMessage("");

    // Mesaj kutusunu en alta kaydır
    setTimeout(() => {
      if (messageBoxRef.current) {
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
      }
    }, 100);
  };

  // enter ile mesaj gönderme
  const onKeyPress = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();
      setcurMessage(value);
      addMessage();
    }
  };

  // son mesaja inme
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messages]);

  document.title = "Talepler | Global Assistance";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Card>
                  <CardBody>
                    <div className="w-100">
                      <div className="chat-content d-lg-flex">
                        <div className="w-100 position-relative">
                          <div className="position-relative">
                            <div className="p-3">
                              <Row className="align-items-center">
                                <Col sm={4} xs={8}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 ">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                          <span className="user-status"></span>
                                        </div>
                                        <div className="flex-grow-1 ">
                                          <h5 className="text-truncate mb-0 fs-16">
                                            <a
                                              className="text-reset username"
                                              data-bs-toggle="offcanvas"
                                              href="#userProfileCanvasExample"
                                              aria-controls="userProfileCanvasExample"
                                            >
                                              Talep Başlığı
                                            </a>
                                          </h5>
                                          <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                            <small>durum</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={8} xs={4}>
                                  <ul className="list-inline user-chat-nav text-end mb-0">
                                    <li className="list-inline-item m-0">
                                      <Dropdown
                                        isOpen={settings_Menu}
                                        toggle={toggleSettings}
                                      >
                                        <DropdownToggle
                                          className="btn btn-ghost-secondary btn-icon"
                                          tag="button"
                                        >
                                          <FeatherIcon
                                            icon="more-vertical"
                                            className="icon-sm"
                                          />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            href="#"
                                            className="d-block d-lg-none user-profile-show"
                                          >
                                            <i className="ri-user-2-fill align-bottom text-muted me-2"></i>{" "}
                                            Kullanıcı Bilgileri
                                          </DropdownItem>
                                          <DropdownItem href="#">
                                            <i className="ri-inbox-archive-line align-bottom text-muted me-2"></i>{" "}
                                            Talebi Kapat
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>

                            <div
                              className="position-relative"
                              id="users-chat"
                              style={{ backgroundColor: "#FDFDFD" }}
                            >
                              <div
                                className="chat-conversation p-3 p-lg-4"
                                id="chat-conversation"
                              >
                                <PerfectScrollbar
                                  containerRef={(ref) =>
                                    (messageBoxRef.current = ref)
                                  }
                                  style={{ padding: "0 1rem" }}
                                >
                                  <div id="elmLoader"></div>
                                  <ul
                                    className="list-unstyled chat-conversation-list"
                                    id="users-conversation"
                                  >
                                    {messages &&
                                      messages.map((message, key) => (
                                        <li
                                          className={
                                            (userProfile.data.Role ==
                                              "SuperAdmin" ||
                                              userProfile.data.Role ==
                                                "Admin") &&
                                            (message.role == "Admin" ||
                                              message.role == "SuperAdmin")
                                              ? "chat-list right"
                                              : message.role ==
                                                userProfile.data.Role
                                              ? "chat-list right"
                                              : "chat-list left"
                                          }
                                          key={key}
                                        >
                                          <div className="conversation-list">
                                            <div className="user-chat-content">
                                              <div className="conversation-name">
                                                <small className="text-muted time">
                                                  {message.NameSurname} -{" "}
                                                  {message.Role}
                                                </small>
                                              </div>
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <p className="mb-0 ctext-content">
                                                    {message.message}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </PerfectScrollbar>
                              </div>
                            </div>

                            <div className="chat-input-section p-3 p-lg-4">
                              <form id="chatinput-form">
                                <Row className="g-0 align-items-center">
                                  <div className="col">
                                    <div className="chat-input-feedback">
                                      Lütfen mesajınızı giriniz
                                    </div>
                                    <textarea
                                      value={curMessage}
                                      onKeyDown={onKeyPress}
                                      onChange={(e) =>
                                        setcurMessage(e.target.value)
                                      }
                                      className="form-control chat-input bg-light border-light"
                                      id="chat-input"
                                      placeholder="Lütfen mesajınızı giriniz..."
                                      autoComplete="off"
                                      rows={3} // Varsayılan yüksekliği ayarlar
                                      style={{
                                        resize: "none", // Kullanıcının alanı boyutlandırmasını engeller
                                        overflow: "hidden", // Taşma durumunda scrollbar'ı gizler
                                      }}
                                    />
                                  </div>
                                  <div className="col-auto">
                                    <div className="chat-input-links ms-2">
                                      <div className="links-list-item">
                                        <Button
                                          type="button"
                                          color="primary"
                                          onClick={() => {
                                            addMessage();
                                          }}
                                          className="chat-send waves-effect waves-light"
                                        >
                                          <i className="ri-send-plane-2-fill align-bottom"></i>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Ticket;
