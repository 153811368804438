import React, { Fragment, useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useSelector } from "react-redux";
// import { pay3D } from "../../slices/apps/paymentSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

// diğer importlar
import ReactInputMask from "react-input-mask";
import classnames from "classnames";

// data dosyaları
import CityJson from "../../common/data/city.json";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler
    .map((ilce) => ({
      label: ilce.ilce_adi,
      value: ilce.ilce_adi,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
})).sort((a, b) => a.label.localeCompare(b.label));

const carsInfo = [
  {
    typeId: 1,
    typeName: "Otomobil",
    brands: [
      { brandId: 1, brandName: "Toyota" },
      { brandId: 2, brandName: "BMW" },
      { brandId: 3, brandName: "Mercedes-Benz" },
    ],
  },
  {
    typeId: 2,
    typeName: "Motosiklet",
    brands: [
      { brandId: 4, brandName: "Harley-Davidson" },
      { brandId: 5, brandName: "Yamaha" },
      { brandId: 6, brandName: "Kawasaki" },
    ],
  },
  {
    typeId: 3,
    typeName: "Kamyon",
    brands: [
      { brandId: 7, brandName: "Volvo" },
      { brandId: 8, brandName: "Scania" },
      { brandId: 9, brandName: "MAN" },
    ],
  },
];

// regex, değişkenler
const phoneRegExp = /^[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}$/;

const Checkout = () => {
  // states
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [progressbarvalue, setprogressbarvalue] = useState(0);

  const { packageState } = useSelector((state) => {
    return {
      packageState: state.package.package,
    };
  }, shallowEqual);

  const plateValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      plate: "",
    },

    validationSchema: Yup.object({
      plate: Yup.string().required("Lütfen plaka giriniz"),
    }),

    onSubmit: async (values) => {
      toggleTab(2, 33);
    },
  });

  const customerValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      packageId: packageState.id,
      customerName: "",
      phoneNumber: "",
      email: "",
      isActive: true,
      taxNumber: "",
      city: "Adana",
      district: "",
      address: "",
    },

    validationSchema: Yup.object({
      customerName: Yup.string().required("Lütfen müşteri bilgisi girin"),
      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .nullable()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .notRequired(),
      taxNumber: Yup.string()
        .matches(/^[0-9]+$/, "VKN/TCKN sadece sayılardan oluşmalıdır")
        .min(10, "VKN/TCKN en az 10 karakter olmalıdır")
        .max(11, "VKN/TCKN en fazla 11 karakter olmalıdır")
        .required("Lütfen VKN/TCKN girin"),

      city: Yup.string().required("Lütfen il seçin"),
      district: Yup.string().required("Lütfen ilçe seçin"),
      address: Yup.string(),
    }),

    onSubmit: async (values) => {
      toggleTab(3, 66);
    },
  });

  const carValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      usageType: "",
      modelYear: "",
      brand: "",
      model: "",
    },

    validationSchema: Yup.object({
      usageType: Yup.string().required("Araç tipi seçin"),

      modelYear: Yup.number()
        .required("Model yılı seçin")
        .min(1900, "Geçersiz model yılı")
        .max(new Date().getFullYear(), `Geçersiz model yılı`),

      brand: Yup.string().required("Marka seçin"),

      model: Yup.string().required("Model seçin"),
    }),

    onSubmit: async (values) => {
      toggleTab(4, 100);
    },
  });

  const ccValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },

    validationSchema: Yup.object({
      cardHolderName: Yup.string().required("Lütfen ad soyad girin"),
      cardNumber: Yup.string()
        .matches(
          /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
          "Geçersiz kart numarası"
        )
        .required("Lütfen kart numarası girin"),
      expiryDate: Yup.string()
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Geçersiz tarih formatı (AA/YY)"
        )
        .required("Lütfen son kullanma tarihini girin"),
      cvc: Yup.string()
        .matches(/^[0-9]{3}$/, "Geçersiz CVV")
        .required("Lütfen CVV girin"),
    }),

    onSubmit: async (values) => {
      const { cardHolderName, cardNumber, expiryDate, cvc } = values;
      const [expMonth, expYear] = expiryDate.split("/");
      const cardNumberEnd = cardNumber.replaceAll(" ", "");

      const paymentData = {
        licenceId: "",
        cardHolderName,
        cardNumber: cardNumberEnd,
        expMonth: parseInt(expMonth, 10),
        expYear: parseInt(expYear, 10),
        cvc,
        installmentNumber: 1,
        description: "test",
        bankName: "test",
        ...plateValidation.values,
        ...customerValidation.values,
        ...carValidation.values,
      };

      console.log(paymentData);

      // İşlemlerinizi burada gerçekleştirin
      // try {
      //   const resultAction = await dispatch(pay3D(paymentData));

      //   if (resultAction.meta.requestStatus === "rejected") {
      //     console.log(resultAction);
      //   }
      // } catch (error) {
      //   console.error("Failed:", error);
      // }
    },
  });

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col lg={7}>
              <div className="progress-nav mb-4">
                <Progress value={progressbarvalue} style={{ height: "1px" }} />

                <Nav
                  className="nav-pills progress-bar-tab custom-nav z-1 position-relative"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 1,
                          done: activeTab <= 5 && activeTab >= 0,
                        },
                        "rounded-pill"
                      )}
                      onClick={() => {
                        toggleTab(1, 0);
                      }}
                      tag="button"
                      type="submit"
                    >
                      1
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 2,
                          done: activeTab <= 5 && activeTab > 1,
                        },
                        "rounded-pill"
                      )}
                      onClick={() => {
                        plateValidation.handleSubmit();
                      }}
                      tag="button"
                      type="submit"
                    >
                      2
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 3,
                          done: activeTab <= 5 && activeTab > 2,
                        },
                        "rounded-pill"
                      )}
                      onClick={() => {
                        customerValidation.handleSubmit();
                      }}
                      tag="button"
                      type="submit"
                    >
                      3
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="#"
                      id="pills-gen-info-tab"
                      className={classnames(
                        {
                          active: activeTab === 4,
                          done: activeTab <= 5 && activeTab > 3,
                        },
                        "rounded-pill"
                      )}
                      onClick={() => {
                        carValidation.handleSubmit();
                      }}
                      tag="button"
                      type="submit"
                    >
                      4
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      plateValidation.handleSubmit();
                      return false;
                    }}
                    action="#"
                    autoComplete="off"
                  >
                    <Row className="align-items-start">
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="plate" className="form-label">
                            Plaka
                          </Label>
                          <Input
                            name="plate"
                            className="form-control"
                            placeholder="Plaka"
                            type="text"
                            autoComplete="off"
                            maxLength={50}
                            onChange={(e) => {
                              const value = e.target.value.toUpperCase();
                              plateValidation.setFieldValue("plate", value);
                            }}
                            onBlur={plateValidation.handleBlur}
                            value={plateValidation.values.plate || ""}
                            invalid={
                              plateValidation.touched.plate &&
                              plateValidation.errors.plate
                                ? true
                                : false
                            }
                          />
                          {plateValidation.touched.plate &&
                            plateValidation.errors.plate && (
                              <FormFeedback type="invalid">
                                {plateValidation.errors.plate}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center gap-3 mt-4">
                      <Button
                        className="btn btn-sm ms-auto nexttab nexttab"
                        color="primary"
                        type="submit"
                      >
                        Devam Et
                      </Button>
                    </div>
                  </Form>
                </TabPane>

                <TabPane tabId={2}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      customerValidation.handleSubmit();
                      return false;
                    }}
                    action="#"
                    autoComplete="off"
                  >
                    <Row className="align-items-start">
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="taxNumber">
                            VKN/TCKN
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="taxNumber"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                customerValidation.touched.taxNumber &&
                                customerValidation.errors.taxNumber
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={customerValidation.handleChange}
                              value={customerValidation.values.taxNumber}
                            />
                          </InputGroup>
                          {customerValidation.touched.taxNumber &&
                            customerValidation.errors.taxNumber && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {customerValidation.errors.taxNumber}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="customerName" className="form-label">
                            Ad Soyad / Firma Ünvanı
                          </Label>
                          <Input
                            name="customerName"
                            className="form-control"
                            placeholder="Ad Soyad / Firma Ünvanı"
                            type="text"
                            autoComplete="off"
                            onChange={customerValidation.handleChange}
                            onBlur={customerValidation.handleBlur}
                            value={customerValidation.values.customerName || ""}
                            invalid={
                              customerValidation.touched.customerName &&
                              customerValidation.errors.customerName
                                ? true
                                : false
                            }
                          />
                          {customerValidation.touched.customerName &&
                            customerValidation.errors.customerName && (
                              <FormFeedback type="invalid">
                                {customerValidation.errors.customerName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            E-posta Adresi
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Kullanıcı E-posta Adresi"
                            type="text"
                            autoComplete="off"
                            onChange={customerValidation.handleChange}
                            onBlur={customerValidation.handleBlur}
                            value={customerValidation.values.email || ""}
                            invalid={
                              customerValidation.touched.email &&
                              customerValidation.errors.email
                                ? true
                                : false
                            }
                          />
                          {customerValidation.touched.email &&
                            customerValidation.errors.email && (
                              <FormFeedback type="invalid">
                                {customerValidation.errors.email}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="phoneNumber">
                            Telefon Numarası
                          </Label>
                          <InputGroup>
                            <span
                              className="input-group-text "
                              style={{
                                width: "44px",
                                justifyContent: "center",
                              }}
                              id="inputGroupPrepend2"
                            >
                              0
                            </span>
                            <ReactInputMask
                              mask="999 999 99 99"
                              maskChar=" "
                              name="phoneNumber"
                              alwaysShowMask
                              className={
                                customerValidation.touched.phoneNumber &&
                                customerValidation.errors.phoneNumber
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={customerValidation.handleChange}
                              value={
                                customerValidation.values.phoneNumber || ""
                              }
                            />
                          </InputGroup>
                          {customerValidation.touched.phoneNumber &&
                            customerValidation.errors.phoneNumber && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {customerValidation.errors.phoneNumber}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="citySelect" className="form-label">
                            İl
                          </Label>
                          <Select
                            placeholder="İl seçiniz"
                            id="citySelect"
                            name="city"
                            options={city}
                            value={city.find(
                              (item) =>
                                item.value === customerValidation.values.city
                            )}
                            onChange={(value) => {
                              customerValidation.setFieldValue(
                                "city",
                                value.value
                              );
                              customerValidation.setFieldValue(
                                "district",
                                null
                              );
                            }}
                            onBlur={customerValidation.handleBlur}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  customerValidation.touched.city &&
                                  customerValidation.errors.city &&
                                  !state.isFocused
                                    ? "#e30a17"
                                    : "#e9ebec",
                              }),
                            }}
                          />
                          {customerValidation.touched.city &&
                            customerValidation.errors.city && (
                              <div className="d-block invalid-feedback">
                                {customerValidation.errors.city}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="districtSelect"
                            className="form-label"
                          >
                            İlçe
                          </Label>
                          <Select
                            isDisabled={!customerValidation.values.city}
                            placeholder="İlçe seçiniz"
                            id="districtSelect"
                            name="district"
                            options={
                              city.find(
                                (item) =>
                                  item.value === customerValidation.values.city
                              )?.districts || []
                            }
                            value={
                              city
                                .find(
                                  (item) =>
                                    item.value ===
                                    customerValidation.values.city
                                )
                                ?.districts.find(
                                  (district) =>
                                    district.value ===
                                    customerValidation.values.district
                                ) || ""
                            }
                            onChange={(value) => {
                              customerValidation.setFieldValue(
                                "district",
                                value.value
                              );
                            }}
                            onBlur={customerValidation.handleBlur}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  customerValidation.touched.district &&
                                  customerValidation.errors.district &&
                                  !state.isFocused
                                    ? "#e30a17"
                                    : "#e9ebec",
                              }),
                            }}
                          />
                          {customerValidation.touched.district &&
                            customerValidation.errors.district && (
                              <div className="d-block invalid-feedback">
                                {customerValidation.errors.district}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="address" className="form-label">
                            Adres
                          </Label>
                          <Input
                            name="address"
                            className="form-control"
                            placeholder="Mahalle, cadde, sokak..."
                            type="textarea"
                            autoComplete="off"
                            rows="2"
                            onChange={customerValidation.handleChange}
                            onBlur={customerValidation.handleBlur}
                            value={customerValidation.values.address || ""}
                            invalid={
                              customerValidation.touched.address &&
                              customerValidation.errors.address
                                ? true
                                : false
                            }
                            style={{ resize: "none" }}
                          />
                          {customerValidation.touched.address &&
                            customerValidation.errors.address && (
                              <FormFeedback type="invalid">
                                {customerValidation.errors.address}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center gap-3 mt-4">
                      <Button
                        className="btn btn-sm ms-auto nexttab nexttab"
                        color="primary"
                        type="submit"
                      >
                        Devam Et
                      </Button>
                    </div>
                  </Form>
                </TabPane>

                <TabPane tabId={3}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      carValidation.handleSubmit();
                      return false;
                    }}
                    action="#"
                    autoComplete="off"
                  >
                    <Row className="align-items-start">
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="usageTypeSelect"
                            className="form-label"
                          >
                            Araç Tipi
                          </Label>
                          <Select
                            placeholder="Araç tipi seçiniz"
                            id="usageTypeSelect"
                            name="usageType"
                            options={carsInfo.map((item) => ({
                              value: item.typeName,
                              label: item.typeName,
                            }))}
                            value={carsInfo
                              .map((item) => ({
                                value: item.typeName,
                                label: item.typeName,
                              }))
                              .find(
                                (option) =>
                                  option.value ===
                                  carValidation.values.usageType
                              )}
                            onChange={(value) => {
                              carValidation.setFieldValue(
                                "usageType",
                                value.value
                              );
                              carValidation.setFieldValue("brand", null); // Marka seçimini temizle
                            }}
                            onBlur={carValidation.handleBlur}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  carValidation.touched.usageType &&
                                  carValidation.errors.usageType &&
                                  !state.isFocused
                                    ? "#e30a17"
                                    : "#e9ebec",
                              }),
                            }}
                          />
                          {carValidation.touched.usageType &&
                            carValidation.errors.usageType && (
                              <div className="d-block invalid-feedback">
                                {carValidation.errors.usageType}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="modelYear">
                            Model Yılı
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="9999"
                              maskChar=" "
                              name="modelYear"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                carValidation.touched.modelYear &&
                                carValidation.errors.modelYear
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={carValidation.handleChange}
                              value={carValidation.values.modelYear}
                            />
                          </InputGroup>
                          {carValidation.touched.modelYear &&
                            carValidation.errors.modelYear && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {carValidation.errors.modelYear}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="brandSelect" className="form-label">
                            Marka
                          </Label>
                          <Select
                            placeholder="Marka seçiniz"
                            id="brandSelect"
                            name="brand"
                            options={
                              carsInfo
                                .find(
                                  (item) =>
                                    item.typeName ===
                                    carValidation.values.usageType
                                )
                                ?.brands.map((brand) => ({
                                  value: brand.brandName,
                                  label: brand.brandName,
                                })) || []
                            }
                            value={
                              carsInfo
                                .find(
                                  (item) =>
                                    item.typeName ===
                                    carValidation.values.usageType
                                )
                                ?.brands.map((brand) => ({
                                  value: brand.brandName,
                                  label: brand.brandName,
                                }))
                                .find(
                                  (option) =>
                                    option.value === carValidation.values.brand
                                ) || ""
                            }
                            onChange={(value) => {
                              carValidation.setFieldValue("brand", value.value);
                            }}
                            onBlur={carValidation.handleBlur}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor:
                                  carValidation.touched.brand &&
                                  carValidation.errors.brand &&
                                  !state.isFocused
                                    ? "#e30a17"
                                    : "#e9ebec",
                              }),
                            }}
                          />
                          {carValidation.touched.brand &&
                            carValidation.errors.brand && (
                              <div className="d-block invalid-feedback">
                                {carValidation.errors.brand}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="model" className="form-label">
                            Model
                          </Label>
                          <Input
                            name="model"
                            className="form-control"
                            placeholder="Model"
                            type="text"
                            autoComplete="off"
                            onChange={carValidation.handleChange}
                            onBlur={carValidation.handleBlur}
                            value={carValidation.values.model || ""}
                            invalid={
                              carValidation.touched.model &&
                              carValidation.errors.model
                                ? true
                                : false
                            }
                          />
                          {carValidation.touched.model &&
                            carValidation.errors.model && (
                              <FormFeedback type="invalid">
                                {carValidation.errors.model}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center gap-3 mt-4">
                      <Button
                        className="btn btn-sm ms-auto nexttab nexttab"
                        color="primary"
                        type="submit"
                      >
                        Devam Et
                      </Button>
                    </div>
                  </Form>
                </TabPane>

                <TabPane tabId={4}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      ccValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row className="g-2 mb-2">
                      <Col lg={12}>
                        <div>
                          <Label
                            htmlFor="cardHolderName"
                            className="form-label"
                          >
                            Ad Soyad
                          </Label>
                          <Input
                            id="cardHolderName"
                            name="cardHolderName"
                            value={ccValidation.values.cardHolderName}
                            type="text"
                            className="form-control pe-5"
                            placeholder="Kart Sahibi Adı Soyadı"
                            onChange={ccValidation.handleChange}
                            onBlur={ccValidation.handleBlur}
                            invalid={
                              ccValidation.touched.cardHolderName &&
                              ccValidation.errors.cardHolderName
                                ? true
                                : false
                            }
                          />
                          {ccValidation.touched.cardHolderName &&
                            ccValidation.errors.cardHolderName && (
                              <FormFeedback type="invalid">
                                {ccValidation.errors.cardHolderName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div>
                          <Label className="form-label" htmlFor="cardNumber">
                            Kart Numarası
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="9999 9999 9999 9999"
                              maskChar=""
                              name="cardNumber"
                              alwaysShowMask={false}
                              inputMode="numeric"
                              type="tel"
                              className={
                                ccValidation.touched.cardNumber &&
                                ccValidation.errors.cardNumber
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Kart Numarası"
                              onChange={ccValidation.handleChange}
                              value={ccValidation.values.cardNumber}
                            />
                          </InputGroup>
                          {ccValidation.touched.cardNumber &&
                            ccValidation.errors.cardNumber && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {ccValidation.errors.cardNumber}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="expiryDate">
                            Son Kullanma Tarihi
                          </Label>
                          <ReactInputMask
                            mask="99/99"
                            maskChar=" "
                            name="expiryDate"
                            inputMode="numeric"
                            className={
                              ccValidation.touched.expiryDate &&
                              ccValidation.errors.expiryDate
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="AA/YY"
                            onChange={ccValidation.handleChange}
                            value={ccValidation.values.expiryDate}
                          />
                          {ccValidation.touched.expiryDate &&
                            ccValidation.errors.expiryDate && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {ccValidation.errors.expiryDate}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="cvc">
                            CVV
                          </Label>
                          <Input
                            id="cvc"
                            name="cvc"
                            inputMode="numeric"
                            maxLength="3"
                            className={
                              ccValidation.touched.cvc &&
                              ccValidation.errors.cvc
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="CVV" // Placeholder eklendi
                            onChange={ccValidation.handleChange}
                            value={ccValidation.values.cvc}
                          />
                          {ccValidation.touched.cvc &&
                            ccValidation.errors.cvc && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {ccValidation.errors.cvc}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              ccValidation.handleSubmit();
                            }}
                          >
                            Satın Al
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </TabContent>
            </Col>
            <Col lg={5}>
              <div className="sticky-side-div mb-4 mt-2">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Toplam Limit:</span>
                            <span className="fw-light">
                              {packageState.totalLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.totalLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Çekici Limit:</span>
                            <span className="fw-light">
                              {packageState.towingLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.towingLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Araç Yaşı:</span>
                            <span className="fw-light">
                              {packageState.ageLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.ageLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Kaza Adet:</span>
                            <span className="fw-light">
                              {packageState.accidentDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.accident}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Arıza Adet:</span>
                            <span className="fw-light">
                              {packageState.faultDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.fault}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Lastik Adet:</span>
                            <span className="fw-light">
                              {packageState.tireDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.tire}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Yakıt:</span>
                            <span className="fw-light">
                              {packageState.fuelDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {packageState.fuel}
                          </span>
                        </td>
                      </tr>
                      <tr className="table-active">
                        <th>Fiyat:</th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {new Intl.NumberFormat("tr-TR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(packageState.price)}{" "}
                            TL
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Checkout;
