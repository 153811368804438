import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchPackage,
  fetchPackages,
  updatePackage,
} from "../../slices/apps/packageSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// diğer importlar
import classnames from "classnames";
import ReactInputMask from "react-input-mask";

// sayfalar, modallar vb.
import PackageTable from "./PackageTable";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";

const PackageUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const fetchData = async () => {
    try {
      await dispatch(fetchPackage(state.id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!(state && state.id)) {
      navigate("/packages-admin");
    } else {
      fetchData();
      packageValidation.resetForm();
    }
  }, [state]);

  const dispatch = useDispatch();

  const { quillRef: descriptionQuillRef, quill: descriptionQuill } = useQuill();
  const { quillRef: agreementQuillRef, quill: agreementQuill } = useQuill();

  const { packageState, packageLoading } = useSelector((state) => {
    return {
      packageState: state.package.package,
      packageLoading: state.package.packageLoading,
    };
  }, shallowEqual);

  const packageValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: state.id,
      packageName: packageState?.packageName,
      price: packageState?.price,
      totalLimit: packageState?.totalLimit,
      totalLimitDesc: packageState?.totalLimitDesc,
      towingLimit: packageState?.towingLimit,
      towingLimitDesc: packageState?.towingLimitDesc,
      ageLimit: packageState?.ageLimit,
      ageLimitDesc: packageState?.ageLimitDesc,
      accident: packageState?.accident,
      accidentDesc: packageState?.accidentDesc,
      fault: packageState?.fault,
      faultDesc: packageState?.faultDesc,
      tire: packageState?.tire,
      tireDesc: packageState?.tireDesc,
      fuel: packageState?.fuel,
      fuelDesc: packageState?.fuelDesc,
      description: packageState?.description,
      agreement: packageState?.agreement,
      picturePath: packageState?.picturePath,
      isActive: true,
    },

    validationSchema: Yup.object({}),

    onSubmit: async (values) => {
      const resultAction = await dispatch(updatePackage(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        navigate("/package-admin");
      }
    },
  });

  useEffect(() => {
    if (descriptionQuill && packageState?.description) {
      descriptionQuill.clipboard.dangerouslyPasteHTML(packageState.description);

      descriptionQuill.blur();
    }
  }, [descriptionQuill, packageState?.description]);

  useEffect(() => {
    if (descriptionQuill) {
      descriptionQuill.on("text-change", () => {
        packageValidation.setFieldValue(
          "description",
          descriptionQuill.root.innerHTML
        );
      });
    }
  }, [descriptionQuill]);

  useEffect(() => {
    if (agreementQuill && packageState?.agreement) {
      agreementQuill.clipboard.dangerouslyPasteHTML(packageState.agreement);

      agreementQuill.blur();
    }
  }, [agreementQuill, packageState?.agreement]);

  useEffect(() => {
    if (agreementQuill) {
      agreementQuill.on("text-change", () => {
        packageValidation.setFieldValue(
          "agreement",
          agreementQuill.root.innerHTML
        );
      });
    }
  }, [agreementQuill]);

  document.title = "Paket Bilgisi Düzenle | Global Assistance";
  if (!(state && state.id) || packageLoading) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={12}>
              <Card>
                <CardHeader>
                  <h5>Paket Düzenle</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      packageValidation.handleSubmit();
                      return false;
                    }}
                    action="#"
                    autoComplete="off"
                  >
                    <Row className="align-items-start">
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="price">
                            Fiyat
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="price"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.price &&
                                packageValidation.errors.price
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.price}
                            />
                          </InputGroup>
                          {packageValidation.touched.price &&
                            packageValidation.errors.price && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.price}
                              </div>
                            )}
                        </div>
                      </Col>

                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="packageName" className="form-label">
                            Paket Adı
                          </Label>
                          <Input
                            name="packageName"
                            className="form-control"
                            placeholder="Paket Adı"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.packageName || ""}
                            invalid={
                              packageValidation.touched.packageName &&
                              packageValidation.errors.packageName
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.packageName &&
                            packageValidation.errors.packageName && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.packageName}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <hr className="my-4 text-muted" />

                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="totalLimit">
                            Toplam Limit
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="totalLimit"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.totalLimit &&
                                packageValidation.errors.totalLimit
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.totalLimit}
                            />
                          </InputGroup>
                          {packageValidation.touched.totalLimit &&
                            packageValidation.errors.totalLimit && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.totalLimit}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label
                            htmlFor="totalLimitDesc"
                            className="form-label"
                          >
                            Total Limit Açıklaması
                          </Label>
                          <Input
                            name="totalLimitDesc"
                            className="form-control"
                            placeholder="Total Limit Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={
                              packageValidation.values.totalLimitDesc || ""
                            }
                            invalid={
                              packageValidation.touched.totalLimitDesc &&
                              packageValidation.errors.totalLimitDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.totalLimitDesc &&
                            packageValidation.errors.totalLimitDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.totalLimitDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="towingLimit">
                            Çekici Limit
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="towingLimit"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.towingLimit &&
                                packageValidation.errors.towingLimit
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.towingLimit}
                            />
                          </InputGroup>
                          {packageValidation.touched.towingLimit &&
                            packageValidation.errors.towingLimit && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.towingLimit}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label
                            htmlFor="towingLimitDesc"
                            className="form-label"
                          >
                            Çekici Limit Açıklaması
                          </Label>
                          <Input
                            name="towingLimitDesc"
                            className="form-control"
                            placeholder="Çekici Limit Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={
                              packageValidation.values.towingLimitDesc || ""
                            }
                            invalid={
                              packageValidation.touched.towingLimitDesc &&
                              packageValidation.errors.towingLimitDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.towingLimitDesc &&
                            packageValidation.errors.towingLimitDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.towingLimitDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="ageLimit">
                            Araç Yaşı
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="ageLimit"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.ageLimit &&
                                packageValidation.errors.ageLimit
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.ageLimit}
                            />
                          </InputGroup>
                          {packageValidation.touched.ageLimit &&
                            packageValidation.errors.ageLimit && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.ageLimit}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="ageLimitDesc" className="form-label">
                            Araç Yaşı Açıklaması
                          </Label>
                          <Input
                            name="ageLimitDesc"
                            className="form-control"
                            placeholder="Araç Yaşı Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.ageLimitDesc || ""}
                            invalid={
                              packageValidation.touched.ageLimitDesc &&
                              packageValidation.errors.ageLimitDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.ageLimitDesc &&
                            packageValidation.errors.ageLimitDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.ageLimitDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="accident">
                            Kaza Adet
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="accident"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.accident &&
                                packageValidation.errors.accident
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.accident}
                            />
                          </InputGroup>
                          {packageValidation.touched.accident &&
                            packageValidation.errors.accident && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.accident}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="accidentDesc" className="form-label">
                            Kaza Adet Açıklaması
                          </Label>
                          <Input
                            name="accidentDesc"
                            className="form-control"
                            placeholder="Kaza Adet Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.accidentDesc || ""}
                            invalid={
                              packageValidation.touched.accidentDesc &&
                              packageValidation.errors.accidentDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.accidentDesc &&
                            packageValidation.errors.accidentDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.accidentDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="fault">
                            Arıza Adet
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="fault"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.fault &&
                                packageValidation.errors.fault
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.fault}
                            />
                          </InputGroup>
                          {packageValidation.touched.fault &&
                            packageValidation.errors.fault && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.fault}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="faultDesc" className="form-label">
                            Arıza Adet Açıklaması
                          </Label>
                          <Input
                            name="faultDesc"
                            className="form-control"
                            placeholder="Arıza Adet Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.faultDesc || ""}
                            invalid={
                              packageValidation.touched.faultDesc &&
                              packageValidation.errors.faultDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.faultDesc &&
                            packageValidation.errors.faultDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.faultDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="tire">
                            Lastik Adet
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="tire"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.tire &&
                                packageValidation.errors.tire
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.tire}
                            />
                          </InputGroup>
                          {packageValidation.touched.tire &&
                            packageValidation.errors.tire && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.tire}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="tireDesc" className="form-label">
                            Lastik Adet Açıklaması
                          </Label>
                          <Input
                            name="tireDesc"
                            className="form-control"
                            placeholder="Lastik Adet Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.tireDesc || ""}
                            invalid={
                              packageValidation.touched.tireDesc &&
                              packageValidation.errors.tireDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.tireDesc &&
                            packageValidation.errors.tireDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.tireDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col sm={3} md={2} lg={2}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="fuel">
                            Yakıt
                          </Label>
                          <InputGroup>
                            <ReactInputMask
                              mask="99999999999"
                              maskChar=" "
                              name="fuel"
                              alwaysShowMask
                              inputMode="numeric"
                              type="tel"
                              className={
                                packageValidation.touched.fuel &&
                                packageValidation.errors.fuel
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={packageValidation.handleChange}
                              value={packageValidation.values.fuel}
                            />
                          </InputGroup>
                          {packageValidation.touched.fuel &&
                            packageValidation.errors.fuel && (
                              <div
                                type="invalid"
                                className="d-block invalid-feedback"
                              >
                                {packageValidation.errors.fuel}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col sm={9} md={10} lg={10}>
                        <div className="mb-3">
                          <Label htmlFor="fuelDesc" className="form-label">
                            Yakıt Açıklaması
                          </Label>
                          <Input
                            name="fuelDesc"
                            className="form-control"
                            placeholder="Yakıt Açıklaması"
                            type="text"
                            autoComplete="off"
                            onChange={packageValidation.handleChange}
                            onBlur={packageValidation.handleBlur}
                            value={packageValidation.values.fuelDesc || ""}
                            invalid={
                              packageValidation.touched.fuelDesc &&
                              packageValidation.errors.fuelDesc
                                ? true
                                : false
                            }
                          />
                          {packageValidation.touched.fuelDesc &&
                            packageValidation.errors.fuelDesc && (
                              <FormFeedback type="invalid">
                                {packageValidation.errors.fuelDesc}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <hr className="my-4 text-muted" />

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="fuelDesc" className="form-label">
                            Açıklama:
                          </Label>
                          <div className="snow-editor" style={{ height: 300 }}>
                            <div ref={descriptionQuillRef} />
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 mt-3">
                          <Label htmlFor="fuelDesc" className="form-label">
                            Sözleşme:
                          </Label>
                          <div className="snow-editor" style={{ height: 300 }}>
                            <div ref={agreementQuillRef} />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex align-items-center gap-3 mt-4">
                      <Button
                        className="btn btn-sm ms-auto nexttab nexttab"
                        color="primary"
                        type="submit"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PackageUpdate;
