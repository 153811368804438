import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// diğer importlar
import { Bounce, toast } from "react-toastify";
import axios from "axios";

import baseApi from "../../api/baseApi";
const loginApi = "https://api.globalassistance.com.tr";
// const loginApi = "http://192.168.50.12:68";

const initialState = {
  isUserData: null,
  authCheckLoading: true,
  authCheckRefresh: true,

  registerLoading: false,
  forgotPasswordLoading: false,
  phoneCodeCheckLoading: false,
  emailCodeCheckLoading: false,
  resetPasswordLoading: false,

  // login
  user: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

export const loginUser = (user, history) => async (dispatch) => {
  dispatch(loadingStart());

  const data = {
    ...user,
    twoFactorCode: "yufus",
    twoFactorRecoveryCode: "yufus",
  };

  const handleLogin = () => {
    axios
      .post(`${loginApi}/Account/Login`, data)
      .then(async (response) => {
        const responseData = {
          data: response.data.Value,
          status: "success",
          token: response.data.Value.AccessToken,
        };

        // const responseData = {
        //   data: response.data.result,
        //   status: "success",
        //   token: response.data.result.accessToken,
        // };

        localStorage.setItem("authUser", JSON.stringify(responseData));
        history("/dashboard");
        dispatch(loginSuccess(responseData));
      })
      .catch((err) => {
        dispatch(
          apiError(err?.response ? err.response.data.errorMessage : err.message)
        );
      });
  };
  try {
    handleLogin();
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const authCheck = createAsyncThunk("auth/authCheck", async () => {
  const user = localStorage.getItem("authUser");

  try {
    const response = await baseApi.get(`/Account/AuthCheck`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(user).token}`,
      },
    });

    return response.data.Value;
  } catch (error) {
    throw new Error(error);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true;
    },
    loadingStart(state) {
      state.loading = true;
    },
    reset_login_flag(state) {
      state.error = null;
      state.loading = false;
      state.errorMsg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authCheck.pending, (state, action) => {
        state.isUserData = null;
        state.authCheckLoading = true;
      })
      .addCase(authCheck.fulfilled, (state, action) => {
        state.isUserData = action.payload;
        state.authCheckLoading = false;
      })
      .addCase(authCheck.rejected, (state, action) => {
        state.isUserData = null;
        state.authCheckLoading = false;
      });
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  loadingStart,
} = authSlice.actions;

export default authSlice.reducer;
