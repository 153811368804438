import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPackage } from "../../slices/apps/packageSlice";

// router
import { useLocation, useNavigate } from "react-router-dom";

// sayfalar, modallar vb.
import Checkout from "./Checkout";

const Pay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      await dispatch(fetchPackage(state.id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!(state && state.id)) {
      navigate("/packages");
    } else {
      fetchData();
    }
  }, [state]);

  const { packageLoading } = useSelector((state) => {
    return {
      packageLoading: state.package.packageLoading,
    };
  }, shallowEqual);

  document.title = "Paket Satın Al | Global Assistance";
  if (!(state && state.id) || packageLoading) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Checkout />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pay;
