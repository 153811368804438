import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

// diğer importlar
import classnames from "classnames";
import parse from "html-react-parser";

// router
import { Link } from "react-router-dom";

const PackageDetailModal = ({ item }) => {
  const [modal, setModal] = useState(false);
  const [tabs] = useState(["0", "1"]);
  const [activeTab, setActiveTab] = useState("0");

  function tog_large(e) {
    setModal(e);
  }

  // tab değeri değiştirme
  const tabChange = (newTab) => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
    }
  };

  return (
    <Fragment>
      <h5 className="mb-1 fw-semibold">
        <Link
          className="link-body-emphasis link-offset-2 text-decoration-underline "
          onClick={() => tog_large(true)}
          id={item.packageName}
        >
          {item.packageName}
        </Link>
      </h5>

      <Modal
        size="xl"
        isOpen={modal}
        toggle={() => {
          tog_large(false);
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myLargeModalLabel"
          toggle={() => {
            tog_large(false);
          }}
        >
          {item.packageName}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={7}>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === tabs[0],
                        })}
                        onClick={() => {
                          tabChange("0");
                        }}
                        type="button"
                      >
                        Açıklama
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === tabs[1],
                        })}
                        onClick={() => {
                          tabChange("1");
                        }}
                        type="button"
                      >
                        Sözleşme
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
              </Card>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={tabs[0]}>
                  <div className="p-2">
                    <div>{parse(item.description)}</div>
                  </div>
                </TabPane>
                <TabPane tabId={tabs[1]}>
                  <div className="p-2">
                    <div>{parse(item.agreement)}</div>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
            <Col lg={5}>
              <div className="sticky-side-div mb-4 mt-2">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Toplam Limit:</span>
                            <span className="fw-light">
                              {item.totalLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.totalLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Çekici Limit:</span>
                            <span className="fw-light">
                              {item.towingLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.towingLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Araç Yaşı:</span>
                            <span className="fw-light">
                              {item.ageLimitDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.ageLimit}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Kaza Adet:</span>
                            <span className="fw-light">
                              {item.accidentDesc}
                            </span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.accident}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Arıza Adet:</span>
                            <span className="fw-light">{item.faultDesc}</span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.fault}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Lastik Adet:</span>
                            <span className="fw-light">{item.tireDesc}</span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.tire}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="d-flex flex-column">
                            <span>Yakıt:</span>
                            <span className="fw-light">{item.fuelDesc}</span>
                          </div>
                        </th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {item.fuel}
                          </span>
                        </td>
                      </tr>
                      <tr className="table-active">
                        <th>Fiyat:</th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {new Intl.NumberFormat("tr-TR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item.price)}{" "}
                            TL
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default PackageDetailModal;
