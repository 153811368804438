import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  users: [],
  usersLoading: true,
  usersRefresh: true,

  roles: [],
  rolesLoading: true,
  rolesRefresh: true,

  createUserLoading: false,
  updateUserLoading: false,
};

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  try {
    const response = await baseApi.get(`/Account/UserList`);

    return response.data.Value;
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
});

export const fetchRoles = createAsyncThunk("user/fetchRoles", async () => {
  try {
    const response = await baseApi.get(`/Account/RoleList`);

    return response.data.Value;
  } catch (error) {
    throw new Error(error.response.data.errorMessage);
  }
});

export const createUser = createAsyncThunk(
  "user/createUser",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Account/CreateUser`, values);

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const model = {
      nameSurname: values.nameSurname,
      phoneNumber: values.phoneNumber,
      isActive: values.isActive,
      resellerId: values.resellerId,
      roleId: values.roleId,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(
        `/Account/UpdateUser/${values.id}`,
        model
      );

      toast.update(loadingToast, {
        render: response.data.result,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.users = [];
        state.usersLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.usersLoading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users = [];
        state.usersLoading = false;
      });

    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.roles = [];
        state.rolesLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.rolesLoading = false;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.roles = [];
        state.rolesLoading = false;
      });

    builder
      .addCase(createUser.pending, (state, action) => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserLoading = false;
        state.usersRefresh = !state.usersRefresh;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserLoading = false;
      });

    builder
      .addCase(updateUser.pending, (state, action) => {
        state.updateUserLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.usersRefresh = !state.usersRefresh;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateUserLoading = false;
      });
  },
});

export default userSlice.reducer;
