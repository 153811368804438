import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { BalanceOverviewCharts } from "./DashboardEcommerceCharts";

const Revenue = () => {
  const [chartData, setchartData] = useState({
    dataColors: "#008FFB,#00E396",
    series: [
      {
        name: "A",
        data: [45, 60, 75, 90, 120, 150, 180, 200, 230, 250, 260, 240],
      },
      {
        name: "B",
        data: [20, 30, 50, 65, 80, 100, 110, 130, 140, 150, 155, 145],
      },
    ],
  });

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">İstatistik</h4>
        </CardHeader>
        <CardBody className="px-0">
          <ul className="list-inline main-chart text-center mb-0">
            <li className="list-inline-item chart-border-left me-0 border-0">
              <h4 className="text-primary">
                ₺584k{" "}
                <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                  A
                </span>
              </h4>
            </li>
            <li className="list-inline-item chart-border-left me-0">
              <h4>
                ₺497k
                <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                  B
                </span>
              </h4>
            </li>
            <li className="list-inline-item chart-border-left me-0">
              <h4>
                <span data-plugin="counterup">3.6</span>%
                <span className="text-muted d-inline-block fs-13 align-middle ms-2">
                  C
                </span>
              </h4>
            </li>
          </ul>

          <div id="revenue-expenses-charts" dir="ltr">
            <BalanceOverviewCharts
              series={chartData.series}
              dataColors='["--vz-success", "--vz-danger"]'
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
