import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  package: {},
  packageLoading: true,
  packageRefresh: true,

  packages: [],
  packagesLoading: true,
  packagesRefresh: true,

  createPackageLoading: false,
  updatePackageLoading: false,
  deletePackageLoading: false,
};

export const fetchPackage = createAsyncThunk(
  "package/fetchPackage",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Package/${id}`);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const fetchPackages = createAsyncThunk(
  "package/fetchPackages",
  async () => {
    try {
      const response = await baseApi.get(`/Package`);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const createPackage = createAsyncThunk(
  "package/createPackage",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      packageName: values.packageName,
      price: values.price,
      totalLimit: values.totalLimit,
      totalLimitDesc: values.totalLimitDesc,
      towingLimit: values.towingLimit,
      towingLimitDesc: values.towingLimitDesc,
      ageLimit: values.ageLimit,
      ageLimitDesc: values.ageLimitDesc,
      accident: values.accident,
      accidentDesc: values.accidentDesc,
      fault: values.fault,
      faultDesc: values.faultDesc,
      tire: values.tire,
      tireDesc: values.tireDesc,
      fuel: values.fuel,
      fuelDesc: values.fuelDesc,
      description: values.description,
      agreement: values.agreement,
      picturePath: values.picturePath,
      isActive: true,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Package`, data);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const updatePackage = createAsyncThunk(
  "package/updatePackage",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      packageName: values.packageName,
      price: values.price,
      totalLimit: values.totalLimit,
      totalLimitDesc: values.totalLimitDesc,
      towingLimit: values.towingLimit,
      towingLimitDesc: values.towingLimitDesc,
      ageLimit: values.ageLimit,
      ageLimitDesc: values.ageLimitDesc,
      accident: values.accident,
      accidentDesc: values.accidentDesc,
      fault: values.fault,
      faultDesc: values.faultDesc,
      tire: values.tire,
      tireDesc: values.tireDesc,
      fuel: values.fuel,
      fuelDesc: values.fuelDesc,
      description: values.description,
      agreement: values.agreement,
      picturePath: values.picturePath,
      isActive: true,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Package/${values.id}`, data);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const deletePackage = createAsyncThunk(
  "package/deletePackage",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Package/${id}`);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const packageSlice = createSlice({
  name: "package",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackage.pending, (state, action) => {
        state.package = {};
        state.packageLoading = true;
      })
      .addCase(fetchPackage.fulfilled, (state, action) => {
        state.package = action.payload;
        state.packageLoading = false;
      })
      .addCase(fetchPackage.rejected, (state, action) => {
        state.package = {};
        state.packageLoading = false;
      });

    builder
      .addCase(fetchPackages.pending, (state, action) => {
        state.packages = [];
        state.packagesLoading = true;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.packages = action.payload;
        state.packagesLoading = false;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.packages = [];
        state.packagesLoading = false;
      });

    builder
      .addCase(createPackage.pending, (state, action) => {
        state.createPackageLoading = true;
      })
      .addCase(createPackage.fulfilled, (state, action) => {
        state.createPackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
      })
      .addCase(createPackage.rejected, (state, action) => {
        state.createPackageLoading = false;
      });

    builder
      .addCase(updatePackage.pending, (state, action) => {
        state.updatePackageLoading = true;
        state.packagesLoading = true;
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.updatePackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
      })
      .addCase(updatePackage.rejected, (state, action) => {
        state.updatePackageLoading = false;
      });

    builder
      .addCase(deletePackage.pending, (state, action) => {
        state.deletePackageLoading = true;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.deletePackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
      })
      .addCase(deletePackage.rejected, (state, action) => {
        state.deletePackageLoading = false;
      });
  },
});

export default packageSlice.reducer;
