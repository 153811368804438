import React, { useEffect } from "react";

//Import Components
import { Container } from "reactstrap";

import HorizontalLayout from "./HorizontalLayout";

const Sidebar = () => {
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div id="scrollbar">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <HorizontalLayout />
            </ul>
          </Container>
        </div>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
