import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

// router
import { Link } from "react-router-dom";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPackages } from "../../slices/apps/packageSlice";

// sayfalar, modallar vb.
import PackageDetailModal from "./PackageDetailModal";

const Package = () => {
  const dispatch = useDispatch();

  const { packages, packagesLoading } = useSelector((state) => {
    return {
      packages: state.package.packages,
      packagesLoading: state.package.packagesLoading,
    };
  }, shallowEqual);

  const fetchData = async () => {
    try {
      await dispatch(fetchPackages());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  document.title = "Paketler | Global Assistance";
  if (packagesLoading) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <div className="text-center pb-2">
                      <h3 className="fw-semibold fs-22">Paketler</h3>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col xl={10}>
                    <Row>
                      {packages?.map((item, key) => (
                        <Col sm={6} md={4} lg={4} key={key}>
                          <Card className="pricing-box ribbon-box right">
                            <CardBody className="p-4 m-2">
                              <div
                                style={{
                                  position: "relative",
                                  height: "200px",
                                  overflow: "hidden",
                                }}
                              >
                                {/* Bulanık Arka Plan */}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url("https://www.acilyolyardimi.com/wp-content/uploads/2020/05/ahtapot-cekici-hizmeti-1024x796.jpg")`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    filter: "blur(10px)",
                                    zIndex: 1,
                                  }}
                                ></div>

                                {/* Ana Görsel */}
                                <img
                                  src="https://www.acilyolyardimi.com/wp-content/uploads/2020/05/ahtapot-cekici-hizmeti-1024x796.jpg"
                                  alt={item.packageName}
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                    zIndex: 2,
                                  }}
                                />
                              </div>
                              <hr className="my-4 text-muted" />
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <PackageDetailModal item={item} />
                                </div>
                              </div>
                              <div className="pt-4">
                                <h2>
                                  {new Intl.NumberFormat("tr-TR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(item.price)}
                                  <sup>
                                    <small>₺ </small>
                                  </sup>
                                </h2>
                              </div>
                              <div className="mt-4">
                                <Link
                                  to="/pay"
                                  state={{ id: item.id }}
                                  className={`btn btn-primary w-100 waves-effect waves-light`}
                                >
                                  Satın Al
                                </Link>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Package;
